import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import { CgTrashEmpty } from "react-icons/cg";
import MainButton from "../components/buttons/MainButton";
import Breadcrumb from "../components/assets/Breadcrumb";
import SidebarNavigation from "../components/tabs/SidebarNavigation";
import ConfirmPopup from "../components/assets/ConfirmPopup";
import { RootState } from "../store/store";
import styles from "./css/ProjectPage.module.css";
import NewAnalysis from "../components/projectData/NewAnalysis";
import General from "../components/projectData/General";
import Resources from "../components/projectData/Resources";
import AllAnalyses from "../components/projectData/AllAnalyses";
import { useDeleteProject } from "../hooks/project/useDeleteProject";
import { clearCurrentProject, setCurrentProject, setFeatchProjects } from "../store/projectSlice";
import { EvaluationData, DetectionData } from "@/types";
import { setMessage, setShowMessage } from "../store/messageSlice";
import { useFetchProjectEvaluations } from '../utils/projectUtils/getAllEvaluations'
import { useFetchProjectDetections } from '../utils/projectUtils/getAllDetections'
import { useFetchProjectModels } from '../utils/projectUtils/getAllModels'
import { useFetchProjectDatasets } from '../utils/projectUtils/getAllDatasets'

const ProjectPage: React.FC = () => {
  const { projectType, projectId } = useParams<{
    projectType: string;
    projectId: string;
  }>();
  const currentProject = useSelector(
    (state: RootState) => state.project.currentProject
  );

  const allProjects = useSelector(
    (state: RootState) => state.project.allProjects
  );
  const projectEvaluation = useSelector<RootState, EvaluationData[] | null>(
    (state) => state.project.projectEvaluations
  );
  const projectDetection = useSelector<RootState, DetectionData[] | null>(
    (state) => state.project.projectDetection
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getModels = useFetchProjectModels();
  const getDadasets = useFetchProjectDatasets();
  const getEvaluations = useFetchProjectEvaluations();
  const getDetections = useFetchProjectDetections();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("General");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [tablesChange, setTablesChange] = useState(false);
  const [height, setHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedTab("General");
    if (allProjects){
      let project = currentProject;
      if(currentProject === null){
          const project_data = allProjects.find((project) => project.id === projectId);
        if(project_data){
          dispatch(setCurrentProject(project_data));
          project = project_data;
        }
      }
      const body = {project_id: projectId as string}
      getModels.mutate(body);
      getDadasets.mutate(body);
      getEvaluations.mutate(body);
      if(project && project.project_type === "BE")
        getDetections.mutate(body);
    }
  }, [projectId]);

  useEffect(() => {
    if (contentRef.current || tablesChange) {
      setTimeout(() => {
        if (contentRef.current) {
          const newHeight =
            selectedTab === "All Analyses"
              ? contentRef.current.offsetHeight + 35
              : contentRef.current.offsetHeight;
          setHeight(newHeight);
        }
      }, 0);
      setTablesChange(false);
    }
  }, [selectedTab, currentProject, projectEvaluation, projectDetection, tablesChange]);

  const deleteProjectMutation = useDeleteProject({
    onSuccess: (data) => {
      dispatch(setFeatchProjects(true));
      setLoadingDelete(false);
      dispatch(clearCurrentProject());
      setConfirmDeleteOpen(false);
      navigate("/home");
    },
    onError: (error) => {
      dispatch(setMessage("Deleting project failed, please try again."));
      dispatch(setShowMessage(true));
      setConfirmDeleteOpen(false);
    },
  });

  const handleConfirmDelete = () => {
    setConfirmDeleteOpen(true);
  };

  const handleCancelDelete = () => {
    setConfirmDeleteOpen(false);
  };

  const handleConfirmDeleteAction = () => {
    setLoadingDelete(true);
    deleteProjectMutation.mutate({ project_id: projectId as string });
  };

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "General":
        return (
          <div className={styles.content} ref={contentRef}>
            <General />
          </div>
        );
      case "New Analysis":
        return (
          <div className={styles.content} ref={contentRef}>
            <NewAnalysis
              setSelectedTab={setSelectedTab}
              projectId={projectId as string}
              projectType={projectType as "me" | "de" | "be"}
              projectName={currentProject?.project_name as string}
            />
          </div>
        );
      case "Resources":
        return (
          <div className={styles.content} ref={contentRef}>
            <Resources project_id={projectId as string} setTablesChange={setTablesChange}/>
          </div>
        );
      case "All Analyses":
        return (
          <div className={styles.content} ref={contentRef}>
            <AllAnalyses
              projectId={projectId as string}
              evaluationsData={projectEvaluation || []}
              detectionsData={projectDetection || []}
              projectType={projectType?.toUpperCase() || ""}
              setTablesChange={setTablesChange}
            />
          </div>
        );
      default:
        return (
          <div className={styles.content} ref={contentRef}>
            General Content
          </div>
        );
    }
  };

  return (
    <Box className={styles.projectPageWrapper}>
      <Box className={styles.container}>
        <Grid item xs={12} className={styles.header}>
          <Breadcrumb
            projectName={currentProject?.project_name as string}
            projectType={projectType as string}
          />
          <MainButton
            label="Delete"
            theme="white"
            onClick={handleConfirmDelete}
            className={styles.deleteButton}
            IconComponent={CgTrashEmpty}
          >
            Delete Project
          </MainButton>
        </Grid>
        <Grid container spacing={1} className={styles.projectPage}>
          <Grid item xs={3}>
            {" "}
            {/* Reduced size for sidebar */}
            <SidebarNavigation
              projectType={projectType as string}
              selectedItem={selectedTab}
              setSelectedItem={handleTabChange}
            />
          </Grid>
          <Grid item xs={9}>
            {" "}
            {/* Increased size for content */}
            <div
              style={{
                height: `${height}px`,
                transition: "height 0.3s ease-in-out",
                overflow: "hidden",
              }}
            >
              {renderContent()}
            </div>
          </Grid>
        </Grid>
        <ConfirmPopup
            open={confirmDeleteOpen}
            onClose={handleCancelDelete}
            onConfirm={handleConfirmDeleteAction}
            title="Confirm Delete"
            content="Are you sure you want to delete this project? This action cannot be undone."
            isLoading={loadingDelete}
          />
      </Box>
    </Box>
  );
};

export default ProjectPage;
