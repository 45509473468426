// src/store/messageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MessageState {
  showMessage: boolean;
  message: string;
}

const initialState: MessageState = {
  showMessage: false,
  message: '',
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setShowMessage: (state, action: PayloadAction<boolean>) => {
      state.showMessage = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
  },
});

export const { setShowMessage, setMessage } = messageSlice.actions;

export default messageSlice.reducer;
