import { useMutation } from '@tanstack/react-query'
import { getAllEvaluations } from '../../api/evalDetectApi'
import { PostParam } from '@/types'

export function useGetAllEvaluations(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: getAllEvaluations,
        onSuccess,
        onError
    })
}