import React from 'react';
import { Box, Typography, Checkbox, FormControlLabel , Tooltip} from '@mui/material';
import { MitigationAlgorithm } from '../../types';
import { truncateText } from '../../utils/truncateText';
import styles from './css/MitigationPreprocessorsTable.module.css';

interface MitigationPreprocessorsTableProps {
  algorithms: MitigationAlgorithm[];
  setAlgorithms: React.Dispatch<React.SetStateAction<MitigationAlgorithm[]>>;
}

const MitigationPreprocessorsTable: React.FC<MitigationPreprocessorsTableProps> = ({ algorithms, setAlgorithms }) => {
  const handleCheckboxChange = (index: number) => {
    setAlgorithms((prevAlgorithms) =>
      prevAlgorithms.map((algorithm, i) =>
        i === index ? { ...algorithm, selected: !algorithm.selected } : algorithm
      )
    );
  };

  const renderDescription = (description: string) => {
    const truncatedText = truncateText(description, 80);
    if (truncatedText !== description) {
      return (
        <Tooltip title={description} arrow placement="top">
          <Typography className={styles.description}>{truncatedText}</Typography>
        </Tooltip>
      );
    }
    return <Typography className={styles.description}>{truncatedText}</Typography>;
  };

  return (
    <Box className={styles.container}>
      <Typography className={styles.tableTitle}>
        Mitigation Preprocessors
      </Typography>
      <div className={styles.tableHeader}>
        <Typography className={styles.headerItem}>Algorithm</Typography>
        <Typography className={styles.headerItem}>Description</Typography>
      </div>
      <div className={styles.tableBody}>
        {algorithms.map((algorithm, index) => (
          <div key={index} className={styles.tableRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={algorithm.selected || false}
                  onChange={() => handleCheckboxChange(index)}
                  className={styles.checkbox}
                />
              }
              label={algorithm.name}
              className={styles.checkboxLabel}
            />
            <Typography className={styles.description}>{renderDescription(algorithm.description)}</Typography>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default MitigationPreprocessorsTable;
