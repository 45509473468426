import { RunBeEvaluation, RunDeEvaluation, RunMeEvaluation, Project, Eval, Detection, GetLatestEvaluations } from '@/types';
import { PostRequest } from './requests';

export const runEvaluation = async (evaluationData: RunBeEvaluation | RunDeEvaluation | RunMeEvaluation) => {
    try {
        return await PostRequest("/eval_detect/run_evaluation", evaluationData);
    } catch (error) {
        console.log('Error in runEvaluation:', error);
        throw error;
    }
}

export const runDetection = async (detectionData: Project) => {
    try {
        return await PostRequest("/eval_detect/run_detection", detectionData);
    } catch (error) {
        console.log('Error in runDetection:', error);
        throw error;
    }
}

export const checkEvalStatus = async (evaluationData: Eval) => {
    try {
        return await PostRequest("/eval_detect/check_eval_status", evaluationData);
    } catch (error) {
        console.log('Error in checkEvalStatus:', error);
        throw error;
    }
}

export const checkDetectionStatus = async (detectionData: Eval | Detection) => {
    try {
        return await PostRequest("/eval_detect/check_detection_status", detectionData);
    } catch (error) {
        console.log('Error in checkDetectionStatus:', error);
        throw error;
    }
}

export const get = async (data: Eval | Detection) => {
    try {
        return await PostRequest("/eval_detect/get", data);
    } catch (error) {
        console.log('Error in get:', error);
        throw error;
    }
}

export const getAllEvaluations = async (data: Project) => {
    try {
        return await PostRequest("/eval_detect/get_all_evaluations", data);
    } catch (error) {
        console.log('Error in getAllEvaluations:', error);
        throw error;
    }
}

export const getAllDetections = async (data: Project) => {
    try {
        return await PostRequest("/eval_detect/get_all_detections", data);
    } catch (error) {
        console.log('Error in getAllDetections:', error);
        throw error;
    }
}

export const stopRun = async (data: Eval | Detection) => {
    try {
        return await PostRequest("/eval_detect/stop_run", data);
    } catch (error) {
        console.log('Error in stopRun:', error);
        throw error;
    }
}

export const getLatestEvaluations = async (data: GetLatestEvaluations) => {
    try {
        return await PostRequest("/eval_detect/get_all_latest_evals", data);
    } catch (error) {
        console.log('Error in getLatestEvaluations:', error);
        throw error;
    }
}