import { useMutation } from '@tanstack/react-query'
import { deleteDataset } from '../../api/configMngApi'
import { PostParam } from '@/types'

export function useDeleteDataset(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: deleteDataset,
        onSuccess,
        onError
    })
}