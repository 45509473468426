import { useMutation } from '@tanstack/react-query'
import { updateProject } from '../../api/projectsApi'
import { PostParam } from '@/types'

export function useUpdateProject(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: updateProject,
        onSuccess,
        onError
    })
}