import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import styles from "./css/CreateProject.module.css";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import MainButton from "../buttons/MainButton";
import { useCreateProject } from "../../hooks/project/useCreateProject";

interface CreateProjectFormProps {
  type: "ME" | "DE" | "BE";
  open: boolean;
  handleClose: (project_id: string| null) => void;
}

const CreateProjectForm: React.FC<CreateProjectFormProps> = ({ type, open, handleClose }) => {
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [touched, setTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const userName = useSelector((state: RootState) => state.user.userName);

  const createProjectMutation = useCreateProject({
    onSuccess(data) {
      setIsLoading(false);
      handleClose(data.project_id);
      setErrorMessage("");
    },
    onError(error) {
      setIsLoading(false);
      setErrorMessage(error.response.data.message);
    },
  })

  const close = () => {
    setTouched(false);
    setErrorMessage("");
    setDescription("");
    setProjectName("");
    handleClose(null);
  }

  const handleProjectNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleBlur = () => {
    setTouched(true);
  };

  const handleSubmit = () => {
    if (projectName === "") {
      setTouched(true);
      return;
    }
    const projectData = {
        project_name: projectName,
        project_type: type,
        project_description:description
    };
    setIsLoading(true);
    createProjectMutation.mutate(projectData);
  };

  return (
    <Dialog open={open} onClose={close} maxWidth="lg">
      <Box className={styles.dialogBox}>
        <DialogTitle>
          <Typography className={styles.dialogTitle}>
            <span className={styles.dialogTitleSpan}>
              {`Create New ${type} Project`}
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <Typography className={styles.label}>
                Project Name
              </Typography>
              <div className={styles.inputContainer}>
                <TextField
                  autoFocus
                  margin="dense"
                  type="text"
                  value={projectName}
                  onChange={handleProjectNameChange}
                  onBlur={handleBlur}
                  fullWidth
                  className={styles.textField}
                  error={touched && projectName === ""}
                  helperText={touched && projectName === "" ? "Enter project name" : ""}
                />
              </div>
              <Grid container item xs={12}>
                <Grid item xs={7}>
                  <Typography className={styles.label}>
                    User Name
                  </Typography>
                  <TextField
                    disabled
                    margin="dense"
                    type="text"
                    value={userName}
                    className={`${styles.textField} ${styles.disabledTextField}`}
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4}>
                  <Typography className={styles.label}>
                    Date
                  </Typography>
                  <TextField
                    disabled
                    margin="dense"
                    type="text"
                    value={new Date().toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                    className={`${styles.textField} ${styles.disabledTextField}`}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Typography className={styles.label}>
                Description Text
              </Typography>
              <TextField
                margin="dense"
                label="Description Text"
                type="text"
                fullWidth
                multiline
                rows={6}
                value={description}
                onChange={handleDescriptionChange}
                className={styles.textField}
              />
            </Grid>

            <Grid item xs={12} className={styles.center}>
              <MainButton
                label="Create Project"
                theme="blue"
                onClick={handleSubmit}
                isLoading={isLoading}
                disabled={isLoading}
              >
              </MainButton>
              <div className={styles.errorMessage}>{errorMessage}</div>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
    
    
  );
}

export default CreateProjectForm;
