import { useMutation } from "@tanstack/react-query";
import { getLatestEvaluations } from "../../api/evalDetectApi";
import { PostParam } from "@/types";

export function useGetLatestEvaluations(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: getLatestEvaluations,
        onSuccess,
        onError
    })
}