// src/store/helpSlice.ts
import { createSlice } from '@reduxjs/toolkit';

interface HelpState {
  enabled: boolean;
}

const initialState: HelpState = {
  enabled: false,
};

const helpSlice = createSlice({
  name: 'help',
  initialState,
  reducers: {
    toggleHelpMode(state) {
      state.enabled = !state.enabled;
    },
  },
});

export const { toggleHelpMode } = helpSlice.actions;
export default helpSlice.reducer;
