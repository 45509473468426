import React, { useEffect } from "react";
import { List } from "@mui/material";
import styles from "./css/SidebarNavigation.module.css";

interface SidebarNavigationProps {
  projectType: string;
  selectedItem: string;
  setSelectedItem: (item: string) => void;
}

const SidebarNavigation: React.FC<SidebarNavigationProps> = ({ projectType, selectedItem, setSelectedItem }) => {
  const menuItems = [
    { text: "General" },
    { text: "New Analysis" },
    { text: "Resources" },
    { text: "All Analyses" },
  ];

  useEffect(() => {
    if (!selectedItem) {
      setSelectedItem("General");
    }
  }, [selectedItem, setSelectedItem]);

  return (
    <div className={styles.sidebarContainer}>
      <List>
        {menuItems.map((item) => (
          <div
            className={`${styles.listItemButton} ${selectedItem === item.text ? styles.selectedItemText : ""}`}
            key={item.text}
            onClick={() => setSelectedItem(item.text)}
          >
            <span className={`${styles.listItemText} ${projectType === "me" ? styles.me: projectType === "de"? styles.de: styles.be}`}>
              {item.text}
            </span>
          </div>
        ))}
      </List>
    </div>
  );
};

export default SidebarNavigation;
