import React, { cloneElement, useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import styles from './css/TooltipWrapper.module.css';

interface TooltipWrapperProps {
  tooltipText: string;
  children: React.ReactElement;
  animationType?: "box" | "text"
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({ tooltipText, children, animationType = "text" }) => {
  const [open, setOpen] = useState(false);
  const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout | null>(null);

  const handleOpen = () => {
    const handler = setTimeout(() => {
      setOpen(true);
    }, 1000); // 1 seconds delay
    setDelayHandler(handler);
  };

  const handleClose = () => {
    if (delayHandler) {
      clearTimeout(delayHandler);
    }
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      if (delayHandler) {
        clearTimeout(delayHandler);
      }
    };
  }, [delayHandler]);

  const childWithGlow = cloneElement(children, {
    className: ` ${children.props.className || ''}`,
    onMouseEnter: handleOpen,
    onMouseLeave: handleClose
  });

  return (
    <Tooltip
      title={tooltipText}
      arrow
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      placement="top"
      classes={{ tooltip: styles.tooltip }}
    >
      {childWithGlow}
    </Tooltip>
  );
};

export default TooltipWrapper;
