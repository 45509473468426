export const checkErrors = (errors: {[key: string]: string}) => {
    if (areAllErrorsEmpty(errors)) {
      return true;
    } else {
      return false;
    }
  };

const areAllErrorsEmpty = (errors: { [key: string]: string }): boolean => {
    return Object.values(errors).every(error => error === "");
};