// src/hooks/firebase/useLogin.ts
import { useMutation } from '@tanstack/react-query'
import { signInWithGoogle } from '../../api/firebase';
import { PostParam, LoginResponse } from '@/types';
import { AppDispatch } from '../../store/store';

interface UseLoginProps extends PostParam {
    dispatch: AppDispatch;
}

export function useLoginWithGoogle(props: UseLoginProps) {
    const { dispatch, onSuccess, onError } = props;

    return useMutation<LoginResponse, Error>({
        mutationFn: () => signInWithGoogle(dispatch),
        onSuccess,
        onError
    })
}
