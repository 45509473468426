import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import MainButton from "../buttons/MainButton";
import styles from "./css/ConfirmPopup.module.css";

interface ConfirmPopupProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content: string;
  isLoading: boolean;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  isLoading,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <MainButton
          label="No"
          onClick={onClose}
          theme="white"
          className={styles.button}
        ></MainButton>
        <MainButton
          label="Yes"
          onClick={onConfirm}
          theme="blue"
          isLoading={isLoading}
          disabled={isLoading}
          className={styles.button}
          spinnerClassName={styles.spinner}
        ></MainButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPopup;
