import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import MainButton from "../../buttons/MainButton";
import DatasetModal from "../modals/DatasetModal";
import { DatasetData, CreateDataset } from "@/types";
import { useCreateDataset } from "../../../hooks/configMng/useCreateDataset";
import { useUpdateDataset } from "../../../hooks/configMng/useUpdateDataset";
import styles from "../css/NewAnalysis.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useFetchProjectDatasets } from "../../../utils/projectUtils/getAllDatasets";
import { setMessage, setShowMessage } from "../../../store/messageSlice";
import TooltipWrapper from "../../../components/assets/TooltipWrapper";

interface DatasetSectionProps {
  projectId: string;
  projectType: "be" | "de" | "me";
  saveLoading: boolean;
  setSaveLoading: (loading: boolean) => void;
  datasetSaved: boolean;
  setDatasetSaved: (loading: boolean) => void;
  runningEval: boolean;
  framework: string;
}

const initialDatasetData: Omit<CreateDataset, "project_id"> = {
  definition: null,
  definition_name: "",
  testset: null,
  testset_name: "",
  class_name: "",
  input_dimensions: "",
  built_in_dataloader: "",
};

const initialSavedDatasetData: Omit<CreateDataset, "project_id"> = {
  definition: null,
  definition_name: "",
  testset: null,
  testset_name: "",
  class_name: "",
  input_dimensions: "",
  built_in_dataloader: "",
};

const DatasetSection: React.FC<DatasetSectionProps> = ({
  projectId,
  projectType,
  saveLoading,
  setSaveLoading,
  datasetSaved,
  setDatasetSaved,
  runningEval,
  framework,
}) => {
  const dispatch = useDispatch();
  const [datasetOpen, setDatasetOpen] = useState(false);
  const [selectedExistingDataset, setSelectedExistingDataset] = useState("");
  const projectDatasets = useSelector<RootState, DatasetData[] | null>(
    (state) => state.project.projectDatasets
  );

  const projectBody = { project_id: projectId };
  const getDatasets = useFetchProjectDatasets();

  const [datasetData, setDatasetData] = useState<
    Omit<CreateDataset, "project_id"> & { project_id: string }
  >({
    ...initialDatasetData,
    project_id: projectId,
  });

  const [savedDatasetData, setSavedDatasetData] = useState<
    Omit<CreateDataset, "project_id"> & { project_id: string }
  >({
    ...initialSavedDatasetData,
    project_id: projectId,
  });

  const updateDatasetMutation = useUpdateDataset({
    onSuccess(data) {
      setDatasetSaved(true);
      setSavedDatasetData(datasetData); // Update only on successful save
      setDatasetOpen(false);
      setSaveLoading(false);
      getDatasets.mutate(projectBody);
    },
    onError(error) {
      dispatch(setMessage(error.response.data.message));
      dispatch(setShowMessage(true));
      setSaveLoading(false);
    },
  });

  const createDatasetMutation = useCreateDataset({
    onSuccess(data) {
      setDatasetSaved(true);
      setSavedDatasetData(datasetData); // Update only on successful save
      setDatasetOpen(false);
      setSaveLoading(false);
      setSelectedExistingDataset(data.dataset_id);
      getDatasets.mutate(projectBody);
    },
    onError(error) {
      dispatch(setMessage(error.response.data.message));
      dispatch(setShowMessage(true));
      setSaveLoading(false);
    },
  });

  const handleDatasetClosed = () => {
    setDatasetData(savedDatasetData); // Reset to saved data
    setDatasetOpen(false);
  };

  const handleSaveDataset = () => {
    setSaveLoading(true);
    if (selectedExistingDataset !== "") {
      const body = {
        dataset_id: selectedExistingDataset,
        project_id: projectId,
        input_dimensions: datasetData?.input_dimensions || "",
        class_name: datasetData?.class_name || "",
        built_in_dataloader: datasetData?.built_in_dataloader || "",
      };
      updateDatasetMutation.mutate(body);
    } else {
      createDatasetMutation.mutate(datasetData as CreateDataset);
    }
  };

  return (
    <>
      <Grid item xs={2} md={2} lg={2}>
        <Box className={styles.buttonBox}>
          {projectType === "me" ? (
            <TooltipWrapper tooltipText="Model data is mandatory before setting the dataset data">
            <div className={styles.buttonWrapper}>
              <MainButton
                label="dataset"
                theme={`${datasetSaved ? "white" : "black"}`}
                className={styles.button}
                onClick={() => {
                  setDatasetOpen(true);
                }}
                disabled={runningEval || framework === ""}
              />
            </div>
          </TooltipWrapper>
          ) : (
            <MainButton
              label="dataset"
              theme={`${datasetSaved ? "white" : "black"}`}
              className={styles.button}
              onClick={() => {
                setDatasetOpen(true);
              }}
              disabled={
                runningEval
              }
            />
          )}
        </Box>
      </Grid>
      <DatasetModal
        projectType={projectType}
        errorMessage={""}
        data={datasetData}
        setData={setDatasetData}
        datasetsData={projectDatasets || []}
        onClose={handleDatasetClosed}
        open={datasetOpen}
        onSave={handleSaveDataset}
        loading={saveLoading}
        selectedExistingDataset={selectedExistingDataset}
        setSelectedExistingDataset={setSelectedExistingDataset}
        modelFramework={framework}
      />
    </>
  );
};

export default DatasetSection;
