import { Project, CreateProject, UpdateProject } from "@/types";
import { PostRequest } from "./requests";

export const createProject = async (data: CreateProject) => {
    try {
        return await PostRequest("/project/create", data);
    } catch (error) {
        console.log('Error in createProject:', error);
        throw error;
    }
}

export const getProject = async (project_id: string) => {
    try {
        return await PostRequest("/project/get", {project_id});
    } catch (error) {
        console.log('Error in getProject:', error);
        throw error;
    }
}

export const updateProject = async (data: UpdateProject) => {
    try {
        return await PostRequest("/project/update", data);
    } catch (error) {
        console.log('Error in updateProject:', error);
        throw error;
    }
}

export const deleteProject = async (data: Project) => {
    try {
        console.log('data:', data);
        return await PostRequest("/project/delete_entities", data);
    } catch (error) {
        console.log('Error in deleteProject:', error);
        throw error;
    }
}

export const getAllProjects = async () => {
    try {
        return await PostRequest("/project/get_all", {});
    } catch (error) {
        console.log('Error in getAllProjects:', error);
        throw error;
    }
}