import React, { ChangeEvent, useState } from "react";
import styles from "./css/YesNoButton.module.css";

interface YesNoButtonProps {
  handleChange: (value: boolean) => void;
}

const YesNoButton: React.FC<YesNoButtonProps> = ({ handleChange }) => {
  const [value, setValue] = useState("no");

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setValue(selectedValue);
    handleChange(selectedValue === "yes");
  };

  return (
    <select className={styles.dropdown} value={value} onChange={onChange}>
      <option value="yes">Yes</option>
      <option value="no">No</option>
    </select>
  );
};

export default YesNoButton;
