export const ColumnsSetting = () => {
    const attacks = [
      { id: 'name', label: 'Name', minWidth: 50 },
      { id: 'description', label: 'Description', minWidth: 200 },
    //   { id: 'type', label: 'Type', minWidth: 50 },
      { id: 'type', label: 'Security Violation', minWidth: 155 },
      { id: 'assumption', label: 'Assumption', minWidth: 50 },
      { id: 'influence', label: 'Influence', minWidth: 50 },
      { id: 'targeting', label: 'Targeting', minWidth: 100 },
    //   { id: 'has_max_iter', label: 'Has Max Iter', minWidth: 100 },
      { id: 'p-norm', label: 'P Norm', minWidth: 90 },
      { id: 'default_max_iter', label: 'Num Iterations', minWidth: 150 },
      { id: 'run_time', label: 'Run Time', minWidth: 100 },
    ];
  
    const defenses = [
      { id: 'name', label: 'Name', minWidth: 150 },
      { id: 'description', label: 'Description', minWidth: 50 },
      { id: 'type', label: 'Type', minWidth: 100 },
    //   { id: 'class_name', label: 'Class Name', minWidth: 200 },
    ];
  
    return { attacks, defenses };
  };
  