import React, { useState } from "react";
import MainButton from "../../buttons/MainButton";
import EvaluationForm from "../../forms/EvaluationForm";
import styles from "../css/NewAnalysis.module.css";
import { ValidationData } from "@/types";
import { useDispatch } from "react-redux";
import { useRunEvaluation } from "../../../hooks/evalDetect/useRunEvaluation";
import { setMessage, setShowMessage } from "../../../store/messageSlice";
import { useFetchProjectEvaluations } from "../../../utils/projectUtils/getAllEvaluations";

interface EvaluationSectionProps {
  projectType: string;
  projectName: string;
  projectId: string;
  continueDisable: boolean;
  validation: ValidationData;
  setValidation: (validation: ValidationData) => void;
  setSelectedTab: (value: string) => void;
}

const EvaluationSection: React.FC<EvaluationSectionProps> = ({
  projectType,
  projectName,
  projectId,
  continueDisable,
  validation,
  setValidation,
  setSelectedTab
}) => {
  const dispatch = useDispatch();
  const [showEvalForm, setShowEvalForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [runEvalLoading, setRunEvalLoading] = useState(false);
  const [analysis, setAnalysis] = useState({
    attacks: [],
    defenses: [],
    analysis: {
      epsilon: 0.2,
      optimize_attacks: "no",
      optimize_defence: "no",
    },
  });

  const getEvaluations = useFetchProjectEvaluations(); 

  const handleContinue = () => {
    if (projectType === "me") {
      setShowEvalForm(true);
    } else {
      setRunEvalLoading(true);
      runEvaluationMutation.mutate({project_id: projectId})
    }
  };

  const runEvaluationMutation = useRunEvaluation({
    onSuccess(data) {
      setIsLoading(false);
      setRunEvalLoading(false);
      handleClose();
      getEvaluations.mutate({project_id: projectId})
      setSelectedTab("All Analyses");
    },
    onError(error) {
      setIsLoading(false);
      dispatch(setMessage(error.response.data.message));
      dispatch(setShowMessage(true));
      setIsLoading(false);
      setRunEvalLoading(false);
    },
  });

  const handleRunEvaluation = () => {
    setIsLoading(true);
    if (projectType === "me") {
      const body = {
        project_id: projectId as string,
        attacks: analysis.attacks,
        defenses: analysis.defenses,
        analysis: analysis.analysis,
      };
      runEvaluationMutation.mutate(body);
    }

    try {
    } catch (error) {}
  };

  const handleClose = () => {
    setShowEvalForm(false);
  };

  return (
    <>
      {projectType !== "be" && (
        <div className={styles.continueButtonContainer}>
          <MainButton
            label={`${
              projectType === "me" ? "Continue" : "Run poison detection"
            }`}
            theme="blue"
            className={`${styles.button} ${
              projectType === "de" ? styles.deButton : ""
            }`}
            onClick={handleContinue}
            isLoading={runEvalLoading}
            disabled={continueDisable || runEvalLoading}
          />
        </div>
      )}
      <EvaluationForm
        isLoading={isLoading}
        analysis={analysis}
        setAnalysis={setAnalysis}
        validation={validation}
        setValidation={setValidation}
        open={showEvalForm}
        onClose={handleClose}
        projectName={projectName}
        projectType={projectType}
        handleRunEvaluation={handleRunEvaluation}
      />
    </>
  );
};

export default EvaluationSection;
