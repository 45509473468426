import React from "react";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { Box, CircularProgress } from "@mui/material";
import { EvaluationDataWithProjectName, ProjectData } from "@/types";
import styles from "./css/ProjectsTable.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentProject } from "../../store/projectSlice";



interface ProjectsTableProps {
  type: "ME" | "DE" | "BE";
  projects: ProjectData[];
  isLoading: boolean;
  latestEvaluations: EvaluationDataWithProjectName[] | null
}

const ProjectsTable: React.FC<ProjectsTableProps> = ({ type, projects, isLoading, latestEvaluations }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "project_name",
      header: "Project Name",
      size: 100,
      Cell: ({ cell }) => (
        <span
          className={`${styles.link} ${styles.data}`}
          onClick={() => handleClick(cell.row.original)}
        >
          {cell.getValue<string>()}
        </span>
      ),
    },
    {
      accessorKey: "project_description",
      header: "Description",
      size: 180,
      muiTableBodyCellProps: {
        className: styles.data,
      },
    },
    {
      accessorKey: "created_at",
      header: "Creation Date",
      size: 100,
      muiTableBodyCellProps: {
        className: styles.data,
      },
    },
    {
      accessorKey: "latest_analysis",
      header: "Latest Analysis",
      size: 100,
      Cell: ({ row }) => (
        <span>
          {latestEvaluations === null ? (
            <CircularProgress size={20} />
          ) : (
            latestEvaluations.find((evaluation: EvaluationDataWithProjectName) => evaluation.project_id === row.original.id)?.start_time.split(" ")[0]
          )}
        </span>
      ),
      muiTableBodyCellProps: {
        className: styles.data,
      },
    },
    // {
    //   accessorKey: "num_iterations",
    //   header: "Number Of Iterations",
    //   size: 120,
    //   muiTableBodyCellProps: {
    //     className: styles.data,
    //   },
    // },
    // {
    //   accessorKey: "elapsed_time",
    //   header: "Elapsed Time",
    //   size: 100,
    //   muiTableBodyCellProps: {
    //     className: styles.data,
    //   },
    // },
    // {
    //   accessorKey: "time_limits",
    //   header: "Time Limits",
    //   size: 100,
    //   muiTableBodyCellProps: {
    //     className: styles.data,
    //   },
    // },
  ];

  const getColorClass = () => {
    switch (type) {
      case "ME":
        return styles.colorME;
      case "DE":
        return styles.colorDE;
      case "BE":
        return styles.colorBE;
      default:
        return "";
    }
  };
  
  const handleClick = (project: ProjectData) => {
    dispatch(setCurrentProject(project));
    navigate(`/${type.toLowerCase()}/${project.id}`);
  };

  return (
    <Box className={`${styles.container} ${getColorClass()}`}>
      <MaterialReactTable
        columns={columns}
        data={projects}
        enableColumnResizing={false}
        enableBottomToolbar={false}
        enableStickyHeader
        enablePagination={false}
        enableColumnActions={false}
        enableSorting={false}
        enableGlobalFilter={false}
        enableFilters={false}
        enableTopToolbar={false}
        muiTableProps={{
          className: styles.table,
        }}
        muiTableFooterProps={{
          className: styles.tableFooter,
        }}
        muiTableContainerProps={{
          className: styles.tableContainer,
        }}
        muiTableHeadCellProps={{
          className: styles.tableHeadCell,
        }}
        muiTableHeadRowProps={{
          className: styles.tableHeadRow,
        }}
        muiTablePaperProps={{
          className: styles.tablePaper,
        }}
        muiTableBodyRowProps={{
          className: styles.tableBodyRow,
        }}
        localization={{
          noRecordsToDisplay: 'No projects to display'
        }}
        state={{
          isLoading: isLoading,
          showAlertBanner: false,
          showProgressBars: false,
        }}
      />
    </Box>
  );
};

export default ProjectsTable;
