// src/store/store.ts
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import projectReducer from './projectSlice';
import helpReducer from './helpSlice';
import messageReducer from './messageSlice'
import clearAllMiddleware from './middleware/clearAllMidleware';

const store = configureStore({
  reducer: {
    user: userReducer,
    project: projectReducer,
    help: helpReducer,
    message: messageReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(clearAllMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
