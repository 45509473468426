import React, { useState } from "react";
import { Box, Grid, Typography, TextField } from "@mui/material";
import styles from "./css/General.module.css";
import MainButton from '../../components/buttons/MainButton';
import { useUpdateProject } from "../../hooks/project/useUpdateProject";
import { RootState } from "../../store/store";
import { useSelector, useDispatch } from 'react-redux';
import { useFetchAllProjects } from "../../utils/projectUtils/getAllProjects";
import { setMessage, setShowMessage } from "../../store/messageSlice";



interface ProjectDetailsProps {

}


const ProjectDetails: React.FC<ProjectDetailsProps> = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const currentProject = useSelector((state: RootState) => state.project.currentProject)
    const [currentDescription, setCurrentDescription] = useState(currentProject?.project_description || "");
    const userName = useSelector((state: RootState) => state.user.userName);
    const getAllProjectMutation = useFetchAllProjects()
    const dispatch = useDispatch();

    const setProjectDescriptionMutation = useUpdateProject({
      onSuccess(data) {
        getAllProjectMutation.mutate();
        dispatch(setMessage('✅ Project description successfully updated.'));
        dispatch(setShowMessage(true));
        setIsEditing(false);
        setIsLoading(false);
      },
      onError(error) {
        dispatch(setMessage(error.response.data.message));
        dispatch(setShowMessage(true));
        setIsLoading(false); 
        setIsLoading(false);
      },
    })

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentDescription(event.target.value);
        setIsEditing(true);
      };

      const handleSave = () => {
        setIsLoading(true);
        if (currentProject) {
          setProjectDescriptionMutation.mutate({
            project_id: currentProject.id,
            project_description: currentDescription
          })
        }
      };

    return (
    <Box>
      <Grid container spacing={2} className={styles.gridContainer}>
        <Grid item xs={4}>
          <Typography className={styles.label}>Project Name</Typography>
          <TextField
            variant="outlined"
            value={currentProject?.project_name}
            InputProps={{ readOnly: true, className: styles.readOnlyInput }}
            className={styles.textField}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Typography className={styles.label}>User Name</Typography>
          <TextField
            variant="outlined"
            value={userName}
            InputProps={{ readOnly: true, className: styles.readOnlyInput }}
            className={styles.textField}
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <Typography className={styles.label}>Creation Date</Typography>
          <TextField
            variant="outlined"
            value={currentProject?.created_at}
            InputProps={{ readOnly: true, className: styles.readOnlyInput }}
            className={styles.textField}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={styles.label}>Description Text</Typography>
          <TextField
            variant="outlined"
            value={currentDescription}
            multiline
            rows={4}
            onChange={handleDescriptionChange}
            className={styles.textFieldEditable}
          />
        </Grid>
        <Grid item xs={12} className={styles.buttonContainer}>
          <MainButton 
            label="Save" 
            theme='white' 
            onClick={handleSave}
            isLoading={isLoading}
            className={`${styles.saveButton} ${isEditing ? styles.saveButtonEditing : ''}`}
        />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectDetails;
