// src/hooks/firebase/useLogin.ts
import { useMutation } from '@tanstack/react-query'
import { Login } from '../../api/firebase';
import { PostParam, LoginData, LoginResponse } from '@/types';
import { AppDispatch } from '../../store/store';

interface UseLoginProps extends PostParam {
    loginData: LoginData;
    dispatch: AppDispatch;
}

export function useLogin(props: UseLoginProps) {
    const { loginData, dispatch, onSuccess, onError } = props;

    return useMutation<LoginResponse, Error>({
        mutationFn: () => Login(loginData, dispatch),
        onSuccess,
        onError
    })
}
