import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import bgIcon from "../icons/CB_icon.svg";
import cyberIcon from "../icons/Cyber_icon.svg";
import MainButton from "../components/buttons/MainButton";
import styles from "./css/LoginPage.module.css";
import { useDispatch } from "react-redux";
import { useLogin } from "../hooks/firebase/useLogin";
import { useGetByFbUID } from "../hooks/user/useGetByFbUID";
import { useResetPassword } from "../hooks/firebase/useResetPassword";
import { useLoginWithGoogle } from "../hooks/firebase/useLoginWithGoogle";
import { UserData } from "@/types";
import { setUser } from "../store/userSlice";
import { setFeatchProjects } from "../store/projectSlice";
import { setMessage, setShowMessage } from "../store/messageSlice";
import { BiRightArrowAlt } from "react-icons/bi";

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }

    // Check if email and password have values and update state
    if (emailRef.current && emailRef.current.value) {
      setEmail(emailRef.current.value);
    }
    if (passwordRef.current && passwordRef.current.value) {
      setPassword(passwordRef.current.value);
    }
  }, []);

  const resetPasswordMutation = useResetPassword({
    onSuccess: (data) => {
      setLoading(false);
      dispatch(setMessage("Reset link sent."));
      dispatch(setShowMessage(true));
    },
    onError(error) {
      dispatch(setMessage("Failed to send reset link, incorrect email."));
      dispatch(setShowMessage(true));
      setLoading(false);
    },
  });

  const getByFbUIDMutation = useGetByFbUID({
    onSuccess: (user: UserData) => {
      const savedData = {
        id: user.id,
        userName: user.user_name,
        userAuth: true,
        token: null,
        refreshToken: null,
      };
      dispatch(setFeatchProjects(true));
      dispatch(setUser(savedData));
      setLoading(false);
      navigate("/home");
    },
    onError: (error) => {
      setLoading(false);
      dispatch(
        setMessage("The user doesn't exist, please contact us to register.")
      );
      dispatch(setShowMessage(true));
    },
  });

  const loginMutation = useLogin({
    loginData: { email, password },
    dispatch,
    onSuccess: (data) => {
      if (data.localId) {
        getByFbUIDMutation.mutate({ firebase_uid: data.localId });
      }
    },
    onError: (error) => {
      dispatch(
        setMessage(
          "Login failed. Please check your username and password and try again."
        )
      );
      dispatch(setShowMessage(true));
      setLoading(false);
    },
  });

  const loginWithGoogleMutation = useLoginWithGoogle({
    dispatch,
    onSuccess(data) {
      if (data.localId) {
        getByFbUIDMutation.mutate({ firebase_uid: data.localId });
      }
    },
    onError(error) {
      dispatch(setMessage("Login with google failed."));
      dispatch(setShowMessage(true));
      setLoading(false);
    },
  });

  const handleSignIn = () => {
    setLoading(true);
    if (isForgotPassword) {
      resetPasswordMutation.mutate(email);
    } else {
      loginMutation.mutate();
    }
  };

  const toggleForgotPassword = () => {
    setIsForgotPassword(!isForgotPassword);
  };

  const handleSignInGoogle = () => {
    loginWithGoogleMutation.mutate();
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        position="relative"
        overflow="hidden"
        className={styles.loginBar}
      >
        <Grid item xs="auto" md="auto" lg="auto">
          <img src={bgIcon} alt="CBG-icon" className={styles.bgIcon} />
        </Grid>
        <Grid item xs="auto" md="auto" lg="auto">
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs="auto" md="auto" lg="auto">
              <Typography color="white" variant="h1" className={styles.title}>
                Auto
              </Typography>
            </Grid>
            <Grid item xs="auto" md="auto" lg="auto">
              <Typography
                variant="h1"
                align="center"
                className={styles.titleBold}
              >
                Defense
              </Typography>
            </Grid>
            <Grid item xs="auto" md="auto" lg="auto">
              <Typography
                variant="h1"
                align="center"
                className={styles.titleExtraBold}
              >
                ML
              </Typography>
            </Grid>
          </Grid>
          <Typography align="center" className={styles.subtitle}>
            Our software gives you the security of trained models and suggests
            defenses and the security of training datasets and ways to clean
            them
          </Typography>
        </Grid>
        <Grid item xs="auto" md="auto" lg="auto">
          <Grid container direction="row" justifyContent="flex-end">
            <img
              src={cyberIcon}
              alt="cyber-icon"
              className={styles.cyberIcon}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        position="absolute"
        top="52%"
        zIndex={1}
        justifyContent="space-between"
        className={styles.iconsContainer}
      >
        <Grid item xs="auto" md="auto" lg="auto">
          <img src={bgIcon} alt="CBG-icon" className={styles.largeIcon} />
        </Grid>
        <Grid item xs="auto" md="auto" lg="auto">
          <img src={cyberIcon} alt="cyber-icon" className={styles.largeIcon} />
        </Grid>
      </Grid>
      <Box marginBottom="9rem"></Box>
      <Box className={styles.signInContainer}>
        <div className={styles.formContainer}>
          <form
            className={`${styles.form} ${isForgotPassword ? styles.swap : ""}`}
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className={`${styles.formContent} ${styles.front}`}>
              <Typography variant="h6" className={styles.signInTitle}>
                Sign in with email and password
              </Typography>
              <div className={styles.inputContainer}>
                <label>Email</label>
                <input
                  ref={emailRef}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className={styles.inputContainer}>
                <label>Password</label>
                <input
                  ref={passwordRef}
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <Box className={styles.buttonContainer}>
                <Link
                  href="#"
                  onClick={toggleForgotPassword}
                  className={styles.forgotPassword}
                >
                  Forgot password?
                </Link>
                <MainButton
                  onClick={handleSignIn}
                  className={`${styles.signInButton}`}
                  theme="blue"
                  label="Sign In"
                  IconComponent={BiRightArrowAlt}
                  isLoading={loading}
                  disabled={loading}
                />
              </Box>
              <div style={{visibility: "hidden"}}>
                <Typography variant="body1" className={styles.orText}>
                  Or
                </Typography>
                <button
                  id="googleSignInButton"
                  className={styles.google}
                  onClick={handleSignInGoogle}
                >
                  <img
                    className={styles.google_img}
                    src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                    alt="Google sign-in"
                  />
                  <span>Sign in with Google</span>
                </button>
              </div>
            </div>
            <div className={`${styles.formContent} ${styles.back}`}>
              <Typography variant="h6" className={styles.signInTitle}>
                Reset your password
              </Typography>
              <div className={styles.inputContainer}>
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <Box className={styles.buttonContainer}>
                <Link
                  href="#"
                  onClick={toggleForgotPassword}
                  className={styles.forgotPassword}
                >
                  Sign in
                </Link>
                <MainButton
                  onClick={handleSignIn}
                  className={`${styles.signInButton} ${styles.forgotButton}`}
                  theme="blue"
                  label="Reset Password"
                  IconComponent={BiRightArrowAlt}
                  isLoading={loading}
                  disabled={loading}
                />
              </Box>
            </div>
          </form>
        </div>
      </Box>
    </>
  );
};

export default LoginPage;
