import { useMutation } from '@tanstack/react-query'
import { getAllProjects } from '../../api/projectsApi'
import { PostParam } from '@/types'

export function useGetAllProjects(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: getAllProjects,
        onSuccess,
        onError
    })
}