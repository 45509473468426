import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import SelectButton from "../../buttons/SelectButton";
import UploadButton from "../../buttons/UploadButton";
import TooltipWrapper from "../../assets/TooltipWrapper";
import styles from "./css/DatasetModal.module.css";
import MainButton from "../../buttons/MainButton";
import { TbDownload } from "react-icons/tb";
import { CreateDataset, DatasetData } from "@/types";
import { convertToCreateDataset } from "../../../utils/convertConfigData";
import { checkErrors } from "../../../utils/checkErrors";
import { isValidNumberArray } from "../../../utils/arrayNumbersCheck";
import { useGenerateURL } from "../../../hooks/configMng/useGenerateURL";

interface DatasetModalProps {
  projectType: "be" | "de" | "me";
  data: CreateDataset;
  setData: React.Dispatch<React.SetStateAction<any>>;
  datasetsData: DatasetData[];
  onSave: () => void;
  onClose: () => void;
  selectedExistingDataset: string;
  setSelectedExistingDataset: (id: string) => void;
  open: boolean;
  loading: boolean;
  errorMessage: string;
  modelFramework?: string;
}

const DatasetModal: React.FC<DatasetModalProps> = ({
  projectType,
  data,
  setData,
  datasetsData,
  onSave,
  onClose,
  open,
  loading,
  selectedExistingDataset,
  setSelectedExistingDataset,
  errorMessage,
  modelFramework = ""
}) => {
  const builtInDataloader =
    projectType === "me"
      ? modelFramework === "PyTorch"? ["PyTorch"]: modelFramework === "Keras" || modelFramework === "TensorFlow"? ["TensorFlow"]: ["CSVDataLoader"]
      : ["DataLoader"];
  const builtInClasses = {
    PyTorch: "PyTorchImgLoader",
    TensorFlow: "TensorFlowLoader",
    CSVDataLoader: "CSV_Dataloader",
    DataLoader: "DataLoader",
    "": "",
  };
  const [selectedType, setSelectedType] = useState<"builtIn" | "custom">(
    "builtIn"
  );

  const [filteredDatasets, setFilteredDataset] = useState<DatasetData[]>([]);

  const templatesPath = "data/dataloader/";
  const files = {
    PyTorch: "torch/PyTorchImgLoader_cifar.py",
    TensorFlow: "TF/Tensorflow_ImageDataloader.py",
    CSVDataLoader: "sklearn/CSV_Dataloader.py",
    DataLoader: "test_dataloader.py",
  };
  const [errors, setErrors] = useState<any>({});
  const [disableSave, setDisableSave] = useState(true);

  const handleSelectChange = (field: string, value: string) => {
    if (field === "built_in_dataloader") {
      setData({
        ...data,
        [field]: value,
        definition: null,
        definition_name: "",
        class_name: builtInClasses[value as keyof typeof builtInClasses],
      });
    } else {
      setData({ ...data, [field]: value });
    }
  };

  const handleDownload = (url: string, filename: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const generateURL = useGenerateURL({
    onSuccess(data) {
      // setUrlToDownload(data.url);
      const framework = builtInDataloader[0];
      handleDownload(data.url, files[framework as keyof typeof files].split("/")[1]);
    },
    onError(error) {
      console.log(`Error fetching URL: ${error}`);
    },
  });

  const handleDownloadTemplate = () => {
    const framework = builtInDataloader[0];
    console.log(framework)
    let templatePath = templatesPath + files[framework as keyof typeof files];
    generateURL.mutate({ file_url: templatePath });
  };

  const handleTextFieldChange = (field: string, value: string) => {
    if (field === "input_dimensions") {
      if (isValidNumberArray(value) || value === "") {
        setErrors((prevErrors: any) => ({ ...prevErrors, [field]: "" }));
      } else {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [field]:
            "Invalid array format. example of valid array, for example: [1,2]",
        }));
      }
    }
    setData({ ...data, [field]: value });
  };

  useEffect(() => {
    if (
      (data.testset !== null || data.testset_name !== "") &&
      (data.input_dimensions !== "" || projectType !== "me") &&
      data.class_name !== "" &&
      (data.built_in_dataloader !== "" || data.definition_name !== "") &&
      checkErrors(errors)
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [data, selectedType]);

  const clearData = () => {
    setData({
      ...data,
      class_name: "",
      input_dimensions: "",
      definition: null,
      definition_name: "",
      built_in_dataloader: "",
    });
    setErrors({});
  };

  useEffect(() => {
    const allowedExtensions = ["xlsx", "csv", "json", "tsv"];
    const csvOptions = ["PyTorch", "Keras", "TensorFlow"]
    const allowedMeExtensions = csvOptions.includes(modelFramework) ? ["zip"] : ["csv"];
    if(modelFramework === ""){
      setFilteredDataset(datasetsData)
    }
    else {
      setFilteredDataset(datasetsData.filter((datas) => allowedMeExtensions.includes(datas.testset_name.split(".")[1]) && (datas.built_in_dataloader === "" || builtInDataloader.includes(datas.built_in_dataloader))))
    }
  }, [datasetsData, modelFramework])

  const handleUpload = (field: string, file: File) => {
    const allowedExtensions = ["xlsx", "csv", "json", "tsv"];
    const csvOptions = ["PyTorch", "Keras", "TensorFlow"]
    const allowedMeExtensions = csvOptions.includes(modelFramework) ? ["zip"] : ["csv"];
    const fileExtension = file.name.split(".").pop()?.toLocaleLowerCase();
    const formattedExtensions = allowedExtensions
      .join(", ")
      .replace(/, ([^,]*)$/, ", and $1");
    const formattedMeExtensions = allowedMeExtensions
      .join(", ")
      .replace(/, ([^,]*)$/, ", and $1");

    if (
      (field === "testset" &&
        ((allowedExtensions.includes(fileExtension as string) &&
          projectType !== "me") ||
          (projectType === "me" &&
            allowedMeExtensions.includes(fileExtension as string)))) ||
      (field === "definition" && fileExtension === "py")
    ) {
      setErrors((prevErrors: any) => ({ ...prevErrors, [field]: "" }));
      if (field === "definition") {
        setData((prevData: CreateDataset) => ({
          ...prevData,
          [field]: file,
          [`${field}_name`]: file.name,
          built_in_dataloader: "",
        }));
      } else {
        setData((prevData: CreateDataset) => ({
          ...prevData,
          [field]: file,
          [`${field}_name`]: file.name,
        }));
      }
      setSelectedExistingDataset("");
      if (field === "testset" && selectedExistingDataset !== "") {
        clearData();
      }
    } else {
      setData((prevData: CreateDataset) => ({
        ...prevData,
        [field]: null,
        [`${field}_name`]: "",
      }));
      const errorMessage =
        field === "testset"
          ? `${projectType === "me"? "Testset" : "Dataset"} file must be from type ${
              projectType === "me" ? formattedMeExtensions : formattedExtensions
            } only.`
          : "Definition file must be from type .py only.";
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [field]: errorMessage,
      }));
    }
  };

  const handleSelectionExistingDataset = (id: string, name: string) => {
    setSelectedExistingDataset(id);
    const existingDataset = datasetsData.find((dataset) => dataset.id === id);
    if (existingDataset === undefined) {
      setData((prevData: CreateDataset) => ({
        ...prevData,
        class_name: "",
        input_dimensions: "",
        definition: null,
        definition_name: "",
        built_in_dataloader: "",
        testset: null,
        testset_name: "",
      }));
    } else {
      if (existingDataset?.built_in_dataloader === null) {
        setSelectedType("custom");
      }
      setData(convertToCreateDataset(existingDataset as DatasetData));
      setErrors({});
    }
  };

  const handleClose = () => {
    setErrors({});
    onClose();
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedType(value as "builtIn" | "custom");
    if (value === "builtIn") {
      setData((prevData: CreateDataset) => ({
        ...prevData,
        class_name: "",
      }));
      setErrors((prevErrors: any) => ({
        ...prevErrors,
        definition: "",
      }));
    } else {
      setData((prevData: CreateDataset) => ({
        ...prevData,
        class_name: "",
        built_in_dataloader: "",
      }));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      maxWidth={false}
      fullWidth
      PaperProps={{
        sx: {
          width: "38rem",
          height: "38rem",
          maxWidth: "none",
          margin: "auto",
        },
      }}
    >
      <DialogTitle>
        Dataset Configuration
        <IconButton onClick={handleClose} className={styles.closeButton}>
          X
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box className={styles.container}>
          <Box className={styles.column}>
            <TooltipWrapper tooltipText="Select a single file, csv/tsv or zip to upload. If you are uploading a zip, the root directory should contain the dataset itself.">
              <Typography>
                <span className={styles.required}>*</span>{ projectType === "me" ? "Testset" : "Dataset"}
              </Typography>
            </TooltipWrapper>
            <UploadButton
              data={filteredDatasets.map((dataset) => ({
                name: dataset.testset_name as string,
                id: dataset.id,
              }))}
              key={data.testset_name}
              updateFunction={handleSelectionExistingDataset}
              selectedValue={data.testset_name}
              onUpload={(file) => handleUpload("testset", file)}
              selection={true}
            />

            <div className={styles.errorMessage}>{errors.testset}</div>

            <Typography>Dataloader Type</Typography>
            <RadioGroup value={selectedType} onChange={handleTypeChange} row>
              <TooltipWrapper tooltipText="Choose a standard dataloader for the chosen framework. Image-based dataloaders normalize images with z-score (0.5,0.5)">
                <FormControlLabel
                  value="builtIn"
                  control={<Radio />}
                  label="Built In"
                  disabled={selectedExistingDataset !== ""}
                />
              </TooltipWrapper>
              <TooltipWrapper tooltipText="Upload the python file that contains your dataloader. Note, if you are working with tabular data, the dataloader must emit rows from a panda dataframe and you must indicate the categorical columns int he panda frame. See documentation.">
                <FormControlLabel
                  value="custom"
                  control={<Radio />}
                  label="Custom"
                  disabled={selectedExistingDataset !== ""}
                />
              </TooltipWrapper>
            </RadioGroup>
            {selectedType === "builtIn" && (
              <>
                <TooltipWrapper tooltipText="Choose a standard dataloader for the chosen framework. Image-based dataloaders normalize images with z-score (0.5,0.5)">
                  <Typography>
                    <span
                      className={`${
                        selectedType === "builtIn"
                          ? styles.required
                          : styles.requiredHidden
                      }`}
                    >
                      *
                    </span>
                    Built In Dataloader
                  </Typography>
                </TooltipWrapper>
                <SelectButton
                  className={styles.builtInSelect}
                  listClassName={styles.builtInSelectList}
                  data={builtInDataloader}
                  selectedValue={data.built_in_dataloader}
                  updateFunction={(value) =>
                    handleSelectChange("built_in_dataloader", value)
                  }
                  isUpload={false}
                  // disabledState={selectedType === "custom"}
                />

                <Typography>Template File</Typography>
                <MainButton
                  label="Download Template"
                  theme="black"
                  className={`${styles.button} ${styles.template}`}
                  onClick={handleDownloadTemplate}
                  IconComponent={TbDownload}
                />
              </>
            )}
            {selectedType === "custom" && (
              <>
                <Typography>
                  <span
                    className={`${
                      selectedType === "custom"
                        ? styles.required
                        : styles.requiredHidden
                    }`}
                  >
                    *
                  </span>
                  Definition
                </Typography>
                <UploadButton
                  updateFunction={(value) =>
                    handleSelectChange("definition_name", value)
                  }
                  selectedValue={data.definition_name}
                  onUpload={(file) => handleUpload("definition", file)}
                  selection={false}
                  disabledState={selectedExistingDataset !== ""}
                  key={data.definition_name}
                />
                {errors.definition && (
                  <div className={styles.errorMessage}>{errors.definition}</div>
                )}
                 <Typography>Template File</Typography>
                <MainButton
                  label="Download Template"
                  theme="black"
                  className={`${styles.button} ${styles.template}`}
                  onClick={handleDownloadTemplate}
                  IconComponent={TbDownload}
                />
              </>
            )}
          </Box>
          <div className={styles.columnBorder}></div>
          <Box className={styles.column}>
            {projectType === "me" && (
              <>
                <Typography>
                  <span className={styles.required}>*</span>Input Dimensions
                </Typography>
                <input
                  type="text"
                  value={data.input_dimensions}
                  onChange={(e) =>
                    handleTextFieldChange("input_dimensions", e.target.value)
                  }
                  className={`${styles.textFiled} ${
                    errors.input_dimensions ? styles.errorBorder : ""
                  }`}
                />

                <div className={`${styles.errorMessageInput}`}>
                  {errors.input_dimensions}
                </div>

                <div className={styles.classPlaceholder}></div>
              </>
            )}
            <TooltipWrapper tooltipText="Enter the name of the data loader class in the uploaded file">
              <Typography>
                <span className={styles.required}>*</span>Class Name
              </Typography>
            </TooltipWrapper>
            <input
              type="text"
              value={data.class_name}
              onChange={(e) =>
                handleTextFieldChange("class_name", e.target.value)
              }
              className={`${styles.textFiled} ${
                errors.clip_values ? styles.errorBorder : ""
              } ${selectedType === "builtIn" ? styles.disabledInput : ""}`}
              disabled={selectedType === "builtIn"}
            />
          </Box>
        </Box>
      </DialogContent>
      <Box className={styles.dialogActionsContainer}>
        <DialogActions>
          <MainButton
            onClick={onSave}
            theme="blue"
            label="Save"
            className={styles.saveButton}
            isLoading={loading}
            disabled={loading || disableSave}
          ></MainButton>
        </DialogActions>
        <div className={styles.errorMessageMain}>{errorMessage}</div>
      </Box>
    </Dialog>
  );
};

export default DatasetModal;
