import { ModelData, CreatModel, DatasetData, CreateDataset } from "@/types";

export const convertToCreatModel = (modelData: ModelData): CreatModel => ({
    model_parameters: null,
    model_parameters_name: modelData?.model_parameters_name || "",
    model_definition: null,  // Default to null
    model_definition_name: modelData?.model_definition_name || "",
    loss: null,  // Default to null
    loss_name: modelData?.loss_name || "",
    built_in_loss: modelData?.built_in_loss || "",
    project_id: modelData?.project_id || "",
    framework: modelData?.framework || "",
    ml_task: modelData?.ml_task || "",
    num_of_classes: modelData?.num_of_classes?.toString() || "",  // Convert to string
    class_name: modelData?.class_name || "",
    clip_values: JSON.stringify(modelData?.clip_values) || "",
    optimizer: modelData?.optimizer || "",
    learning_rate: modelData?.learning_rate?.toString() || "",  // Convert to string
});

export const convertToCreateDataset = (datasetData: DatasetData): CreateDataset => ({
    testset: null,
    testset_name: datasetData?.testset_name || "",
    project_id: datasetData?.project_id || "",
    class_name: datasetData?.class_name || "",
    built_in_dataloader: datasetData?.built_in_dataloader || "",
    definition: null,
    definition_name: datasetData?.definition_name || "",
    input_dimensions: JSON.stringify(datasetData?.input_dimensions) || ""
})