import { useDispatch } from 'react-redux';
import { useGetDatasets } from '../../hooks/configMng/useGetDatasets';
import { setProjectDatasets } from '../../store/projectSlice';

export const useFetchProjectDatasets = () => {
  const dispatch = useDispatch();

  const getProjectDatasetsMutation = useGetDatasets({
    onSuccess(data) {
      dispatch(setProjectDatasets(data));
    },
    onError(error) {
      console.log(`Error fetching datasets: ${error}`);
    },
  });

  return getProjectDatasetsMutation;
};
