import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { ProjectType } from '@/types';

const isProjectType = (value: string): value is ProjectType => {
  return ['me', 'de', 'be'].includes(value);
};

const ProjectTypeGuard: React.FC = () => {
  const { projectType } = useParams<{ projectType: string }>();

  if (!projectType || !isProjectType(projectType)) {
    return <Navigate to="/404" />;  // Replace "/404" with your error page route
  }

  return <Outlet />;
};

export default ProjectTypeGuard;
