import React from "react";
import StopIcon from "@mui/icons-material/Stop";
import styles from "./css/StopButton.module.css";

interface StopButtonProps {
  runId: string;
  onStop: (runId: string, type: string) => void;
  type: "detection" | "evaluation";
}

const StopButton: React.FC<StopButtonProps> = ({ runId, onStop, type }) => {
  return (
    <button
      color="secondary"
      onClick={() => onStop(runId, type)}
      className={styles.stopButton}
    >
      <StopIcon />
    </button>
  );
};

export default StopButton;
