import React, { useState, MouseEvent } from 'react';
import {
  Avatar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store'; 
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';
import styles from './css/GoogleBanner.module.css';
import { clearAll } from '../../store/actions';
import { useLogout } from '../../hooks/firebase/useLogout';

const GoogleBanner: React.FC = () => {
  const { userName } = useSelector((state: RootState) => state.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const useLogoutMutation = useLogout({});

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(clearAll());
    useLogoutMutation.mutate();
    navigate('/');
    handleClose();
  };

  return (
    <Box className={styles.googleBanner}>
      <Avatar src="./css" alt={userName ?? undefined} className={styles.avatar}/>
      <Typography variant="h5" component="div" className={styles.userName}>
        {userName}
      </Typography>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Box>
  );
};

export default GoogleBanner;
