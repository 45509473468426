import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
  DatasetData, 
  ModelData, 
  ProjectData, 
  EvaluationData, 
  DetectionData
} from '@/types';


interface ProjectState {
  currentProject: ProjectData | null;
  projectModels: ModelData[] | null;
  projectDatasets: DatasetData[] | null;
  projectEvaluations: EvaluationData[] | null;
  projectDetection: DetectionData[] | null;
  fetchProjects: boolean;
  allProjects: ProjectData[] | null;
}

const initialState: ProjectState = {
  currentProject: JSON.parse(localStorage.getItem('currentProject') || 'null'),
  projectModels: JSON.parse(localStorage.getItem('projectModels') || 'null'),
  projectDatasets: JSON.parse(localStorage.getItem('projectDatasets') || 'null'),
  projectEvaluations: JSON.parse(localStorage.getItem('projectEvaluations') || 'null'),
  projectDetection: JSON.parse(localStorage.getItem('projectDetection') || 'null'),
  fetchProjects: localStorage.getItem('fetchProjects') === 'true',
  allProjects: JSON.parse(localStorage.getItem('allProjects') || 'null'),
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setCurrentProject: (state, action: PayloadAction<ProjectData>) => {
      state.currentProject = action.payload;
      localStorage.setItem('currentProject', JSON.stringify(action.payload));
    },
    setProjectModels: (state, action) => {
      state.projectModels = action.payload;
      localStorage.setItem('projectModels', JSON.stringify(action.payload));
    },
    setProjectDatasets: (state, action) => {
      state.projectDatasets = action.payload;
      localStorage.setItem('projectDatasets', JSON.stringify(action.payload));
    },
    setProjectEvaluation: (state, action) => {
      state.projectEvaluations = action.payload;
      localStorage.setItem('projectEvaluations', JSON.stringify(action.payload));
    },
    setProjectDetection: (state, action) => {
      state.projectDetection = action.payload;
      localStorage.setItem('projectDetection', JSON.stringify(action.payload));
    },
    setFeatchProjects: (state, action) => {
      state.fetchProjects = action.payload;
      localStorage.setItem('fetchProjects', JSON.stringify(action.payload));
    },
    setAllProjects: (state, action) => {
      state.allProjects = action.payload;
      localStorage.setItem('allProjects', JSON.stringify(action.payload));
    },
    clearCurrentProject: (state) => {
      state.currentProject = null;
      state.projectModels = [];
      state.projectDatasets = [];
      state.projectEvaluations = [];
      state.projectDetection = [];
      localStorage.removeItem('currentProject');
      localStorage.removeItem('projectModels');
      localStorage.removeItem('projectDatasets'); 
      localStorage.removeItem('projectEvaluations');
      localStorage.removeItem('projectDetection');
    },
    clearAllData: (state) => {
      state.currentProject = null;
      state.projectModels = null;
      state.projectDatasets = null;
      state.allProjects = null;
      state.fetchProjects = false;
      state.projectEvaluations = null;
      state.projectDetection = null;
      localStorage.removeItem('currentProject');
      localStorage.removeItem('projectModels');
      localStorage.removeItem('projectDatasets'); 
      localStorage.removeItem('projectEvaluations');
      localStorage.removeItem('projectDetection');
      localStorage.removeItem('allProjects');
      localStorage.removeItem('fetchProjects');
    },
  },
});

export const { 
  setCurrentProject, 
  setProjectModels, 
  setProjectDatasets, 
  setProjectEvaluation, 
  setProjectDetection, 
  setFeatchProjects,
  setAllProjects,
  clearCurrentProject,
  clearAllData,
} = projectSlice.actions;
export default projectSlice.reducer;
