import { IoIosArrowDown } from "react-icons/io";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { createPortal } from "react-dom";
import { Tooltip } from '@mui/material';
import styles from "./css/SelectButton.module.css";
import { truncateText } from "../../utils/truncateText";

interface SelectButtonProps {
  errorState?: boolean;
  errorMessage?: string;
  data: string[];
  displayData?: string[]; // Optional prop for display data
  updateFunction: (value: string) => void;
  disabledState?: boolean;
  selectedValue: string;
  isUpload: boolean;
  className?: string;
  listClassName?: string;
}

const SelectButton: React.FC<SelectButtonProps> = ({
  errorState,
  errorMessage,
  data,
  displayData,
  updateFunction,
  disabledState,
  selectedValue,
  isUpload,
  className,
  listClassName,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownStyles, setDropdownStyles] = useState({});
  const containerRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLUListElement | null>(null);

  const truncatedValue = truncateText(selectedValue, 22);

  const handleToggle = () => {
    if (!disabledState) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (value: string) => {
    setIsOpen(false);
    updateFunction(value);
  };

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  useEffect(() => {
    if (isOpen && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setDropdownStyles({
        top: `${rect.bottom + window.scrollY}px`,
        left: `${rect.left + window.scrollX}px`,
        width: `${rect.width}px`,
      });
    }
  }, [isOpen]);

  const dropdown = (
    <ul
      ref={dropdownRef}
      className={`${styles.selectList} ${listClassName} ${
        isUpload ? styles.uploadList : ""
      }`}
      style={dropdownStyles as React.CSSProperties}
    >
      <li
        key="empty-option"
        className={`${styles.selectListItem} ${styles.emptyListItem} ${
          "empty" === selectedValue ? styles.selectListItemActive : ""
        }`}
        onClick={() => handleSelect("")}
      >
        empty
      </li>
      {data.map((item, index) => (
        <li
          key={index}
          className={`${
            item === selectedValue ? styles.selectListItemActive : ""
          } ${styles.selectListItem}`}
          onClick={() => handleSelect(item)}
        >
          {displayData ? displayData[index] : item} {/* Display names if provided */}
        </li>
      ))}
    </ul>
  );

  return (
    <div ref={containerRef} className={`${styles.container}`}>
      <div
        className={`${styles.selectButton} ${className} ${
          disabledState ? styles.disabled : ""
        } ${isUpload ? styles.upload : ""}`}
        onClick={handleToggle}
      >
        {truncatedValue !== selectedValue ? (
          <Tooltip title={selectedValue} arrow placement="top" classes={{ tooltip: styles.tooltip }}>
            <span>{truncatedValue}</span>
          </Tooltip>
        ) : (
          <span>{truncatedValue || <span className={styles.placeholder}></span>}</span>
        )}
        <span className={`${styles.arrow} ${isOpen ? styles.open : ""}`}>
          <IoIosArrowDown />
        </span>
      </div>
      {isOpen && createPortal(dropdown, document.body)}
      {!isUpload && (
        <div
          className={`${styles.error} ${errorState ? styles.showError : ""}`}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default SelectButton;
