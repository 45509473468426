import { useDispatch } from 'react-redux';
import { useGetModels } from '../../hooks/configMng/useGetModels';
import { setProjectModels } from '../../store/projectSlice';

export const useFetchProjectModels = () => {
  const dispatch = useDispatch();

  const getProjectModelsMutation = useGetModels({
    onSuccess(data) {
      dispatch(setProjectModels(data));
    },
    onError(error) {
      console.log(`Error fetching models: ${error}`);
    },
  });

  return getProjectModelsMutation;
};
