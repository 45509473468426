import { Project, Validation, runValidationData, uploadReq } from '@/types';
import { PostRequest, PostRequestFormData } from './requests';

export const getByProjectId = async (data: Project) => {
    try {
        return await PostRequest("/validation/get", data);
    } catch (error) {
        console.log('Error in getByProjectId:', error);
        throw error;
    }
}

export const runValidation = async (data: runValidationData) => {
    try {
        return await PostRequest("/validation/run_validation", data);
    } catch (error) {
        console.log('Error in runValidation:', error);
        throw error;
    }
}

export const checkStatus = async (data: Validation) => {
    try {
        return await PostRequest("/validation/check_status", data);
    } catch (error) {
        console.log('Error in checkStatus:', error);
        throw error;
    }
}

export const uploadRequirements = async (data: uploadReq) => {
    try {
        return await PostRequestFormData("/validation/upload_requirements", data);
    } catch (error) {
        console.log('Error in upload requirements:', error);
        throw error;
    }
}