import { useMutation } from '@tanstack/react-query'
import { updateDataset } from '../../api/configMngApi'
import { PostParam } from '@/types'

export function useUpdateDataset(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: updateDataset,
        onSuccess,
        onError
    })
}