import { useMutation } from '@tanstack/react-query'
import { createModel } from '../../api/configMngApi'
import { PostParam } from '@/types'

export function useCreateModel(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: createModel,
        onSuccess,
        onError
    })
}