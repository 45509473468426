import React, { useState, useMemo } from "react";
import {
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableSortLabel,
} from "@mui/material";
import styled from "@emotion/styled";
import { FeatureData } from "@/types";

interface MetricsFeaturesTableProps {
  metrics: string[];
  features: FeatureData[];
}

const StyledTableContainer = styled(TableContainer)`
  height: 185px;
  overflow: auto;
  width: 700px;
  border: 2px solid #d9d8e2;
  border-radius: 5px;
  padding: 1rem;
`;

const StyledTableHeaderCell = styled(TableCell)`
  font-weight: bold;
  padding: 8px 16px; // Consistent padding for header cells
  top: -16px;
  text-align: flex-start;
`;

const StyledTableCell = styled(TableCell)`
  padding: 8px 16px;
  text-align: center;
  &:first-of-type {
    text-align: left;
    padding-left: 1rem;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledTable = styled(Table)`
  width: auto;
  max-width: 100%;
`;

const MetricsFeaturesTable: React.FC<MetricsFeaturesTableProps> = ({
  metrics,
  features,
}) => {
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("");

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedFeatures = useMemo(() => {
    if (orderBy) {
      return [...features].sort((a, b) => {
        const aColumn = a.columns.find(col => col.name === orderBy);
        const bColumn = b.columns.find(col => col.name === orderBy);

        const aValue = parseFloat(aColumn?.value || "0");
        const bValue = parseFloat(bColumn?.value || "0");

        if (order === "asc") {
          return aValue - bValue;
        } else {
          return bValue - aValue;
        }
      });
    }
    return features;
  }, [order, orderBy, features]);

  return (
    <StyledTableContainer>
      <StyledTable stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableHeaderCell>Feature</StyledTableHeaderCell>
            {metrics.map((metric, index) => (
              <StyledTableHeaderCell key={index}>
                <TableSortLabel
                  active={orderBy === metric}
                  direction={orderBy === metric ? order : "asc"}
                  onClick={() => handleRequestSort(metric)}
                >
                  {metric}
                </TableSortLabel>
              </StyledTableHeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedFeatures.map((feature, index) => (
            <TableRow key={index}>
              <StyledTableCell>{feature.name}</StyledTableCell>
              {metrics.map((metric, idx) => {
                const column = feature.columns.find(col => col.name === metric);
                return (
                  <StyledTableCell key={idx}>
                    {column ? column.value : ""}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
};

export default MetricsFeaturesTable;