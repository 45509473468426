import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Fade } from "@mui/material";
import MainButton from "../../buttons/MainButton";
import PrivateFeaturesTable from "../../tables/PrivateFeaturesTable";
import MitigationPreprocessorsTable from "../../tables/MitigationPreprocessorsTable";
import MetricsFeaturesTable from "../../tables/MetricsFeaturesTable";
import {
  PrivateFeature,
  MitigationAlgorithm,
  ValidationData,
  Metric,
  FeatureData,
} from "@/types";
import styles from "../css/NewAnalysis.module.css";
import { useDispatch } from "react-redux";
import { setMessage, setShowMessage } from "../../../store/messageSlice";
import { useRunDetection } from "../../../hooks/evalDetect/useRunDetection";
import { useCheckDetectionStatus } from "../../../hooks/evalDetect/useCheckDetectStatus";
import { useRunEvaluation } from "../../../hooks/evalDetect/useRunEvaluation";
import { useFetchProjectEvaluations } from "../../../utils/projectUtils/getAllEvaluations";
import { useFetchProjectDetections } from "../../../utils/projectUtils/getAllDetections";

interface BiasSectionProps {
  projectId: string;
  projectType: string;
  validationDone: boolean;
  validation: ValidationData;
  step: number;
  setStep: (value: number) => void;
  setTab: (value: string) => void;
}

interface DetectionReport {
  features: FeatureData[];
  metrics: string[];
}

const BiasSection: React.FC<BiasSectionProps> = ({
  projectId,
  projectType,
  validationDone,
  validation,
  step,
  setStep,
  setTab,
}) => {
  const dispatch = useDispatch();
  const [showDetection, setShowDetection] = useState(false);
  const [showMitigation, setShowMitigation] = useState(false);
  const [detectionMessage, setDetectionMessage] = useState("");
  const [detectionError, setDetectionError] = useState(false);
  const [evaluationMessage, setEvaluationMessage] = useState("");
  const [privateFeatures, setPrivateFeatures] = useState<PrivateFeature[]>([]);
  const [mitigationAlgorithms, setMitigationAlgorithms] = useState<
    MitigationAlgorithm[]
  >([]);
  const [disableEvaluation, setDisableEvaluation] = useState(false);
  const [detectionLoading, setDetectionLoading] = useState(false);
  const [evaluationLoading, setEvaluationLoading] = useState(false);
  const [detectionReport, setDetectionReport] = useState<DetectionReport>({
    features: [],
    metrics: [],
  });

  const getEvaluations = useFetchProjectEvaluations();
  const getDetections = useFetchProjectDetections();

  useEffect(()=>{
    if(privateFeatures.some(feature => feature.selected && !feature.value && feature.is_categorical)){
      setDisableEvaluation(true);
    }
    else{
      setDisableEvaluation(false);
    }
  },[privateFeatures])

  const runEvaluationMutation = useRunEvaluation({
    onSuccess(data) {
      setEvaluationLoading(false);
      getEvaluations.mutate({ project_id: projectId });
      setTab("All Analyses");
    },
    onError(error) {
      setEvaluationLoading(false);
      setEvaluationMessage(error.response.data.message);
    },
  });

  const checkDetectionStatusMutation = useCheckDetectionStatus({
    onSuccess(data) {
      if (data.detection.status.toLowerCase() === "running") {
        setTimeout(checkDetectionStatus, 5000, data.detection.id);
      } else {
        let message = data.message;
        const statusDone = data.detection.status.toLowerCase() === "done";
        if (statusDone) {
          message = data.detection?.error || data.message;
        }
        if (!statusDone) {
          setDetectionMessage(message);
        }
        setDetectionError(!statusDone);
        setDetectionReport(data.detection.report);
        setDetectionLoading(false);
        getDetections.mutate({ project_id: projectId });
        setStep(2);
      }
    },
    onError(error) {
      setDetectionError(true);
      setDetectionMessage(error.response.data.message);
      setDetectionLoading(false);
    },
  });

  const checkDetectionStatus = (detection_id: string) => {
    checkDetectionStatusMutation.mutate({
      project_id: projectId,
      detection_id: detection_id,
    });
  };

  const runDetectionMutation = useRunDetection({
    onSuccess(data) {
      checkDetectionStatus(data.detection_id);
    },
    onError(error) {
      dispatch(setMessage(error.response.data.message));
      dispatch(setShowMessage(true));
      setDetectionLoading(false);
    },
  });

  const handleStartEvaluation = () => {
    setEvaluationLoading(true);
    runEvaluationMutation.mutate({
      project_id: projectId,
      mitigations: mitigationAlgorithms.filter(mit => mit.selected).map((mitig) => ({
        name: mitig.name,
        description: mitig.description
      })),
      priv_features: privateFeatures.filter(pr => pr.selected).map((priv) => ({
        name: priv.name,
        value: priv.value
      })),
    });
  };

  useEffect(() => {
    if (validation && validation.compatible_mitigations) {
      const initMitigations = validation.compatible_mitigations.map(
        (mitigation) => ({
          name: mitigation.name,
          description: mitigation.description,
          selected: true,
        })
      );
      setMitigationAlgorithms(initMitigations);
    }
    if (validation && validation.features) {
      const initFeatures = validation.features.map((feature) => ({
        name: feature.name,
        values: feature.Values,
        is_categorical: feature.is_categorical,
        selected: false,
        value: "",
      }));
      setPrivateFeatures(initFeatures);
    }
  }, [validation]);

  useEffect(() => {
    if (step === 1) setShowDetection(true);
    if (step === 2) setShowMitigation(true);
  }, [step]);

  return (
    <>
      {projectType === "be" && (
        <>
          {/* Detection Section */}
          <Fade in={showDetection} timeout={500}>
            <Box className={styles.biasDetectionContainer}>
              <Typography className={styles.sectionTitle}>
                Bias Detection
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={2}>
                  <Box className={styles.buttonMessageContainer}>
                    <MainButton
                      label="Run Detection"
                      theme="blue"
                      className={styles.button}
                      onClick={() => {
                        setDetectionLoading(true);
                        runDetectionMutation.mutate({ project_id: projectId });
                      }}
                      disabled={detectionLoading || !validationDone}
                      isLoading={detectionLoading}
                    />
                    <Typography
                      className={`${
                        detectionMessage && detectionError ? styles.showMessage : ""
                      } ${styles.message} ${
                        detectionError ? styles.errorMessage : ""
                      } ${styles.detectionMessage}`}
                    >
                      {detectionMessage}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Box className={styles.tableContainer}>
                    {detectionReport.features.length !== 0 && (
                      <MetricsFeaturesTable
                        features={detectionReport?.features}
                        metrics={detectionReport?.metrics}
                      ></MetricsFeaturesTable>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Fade>
          {/* Evaluation Section */}
          <Fade in={showMitigation} timeout={500}>
            <Box className={styles.biasMitigationContainer}>
              <Typography className={styles.sectionTitle}>
                Bias Mitigation
              </Typography>
              <Box className={styles.sectionButtonContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box className={styles.tableContainer}>
                      <PrivateFeaturesTable
                        features={privateFeatures}
                        setFeatures={setPrivateFeatures}
                      />
                      <MitigationPreprocessorsTable
                        algorithms={mitigationAlgorithms}
                        setAlgorithms={setMitigationAlgorithms}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Box className={styles.biasMitigationButtonContainer}>
                      <MainButton
                        label="Run Evaluation"
                        theme="blue"
                        className={styles.button}
                        onClick={() => handleStartEvaluation()}
                        disabled={evaluationLoading || !validationDone || disableEvaluation}
                        isLoading={evaluationLoading}
                      />
                      <Typography
                        className={`${
                          evaluationMessage ? styles.showMessage : ""
                        } ${styles.message} ${
                          evaluationMessage ? styles.errorMessage : ""
                        } ${styles.evalMessage}`}
                      >
                        {evaluationMessage}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Fade>
        </>
      )}
    </>
  );
};

export default BiasSection;
