import { useDispatch } from 'react-redux';
import { useGetAllEvaluations } from '../../hooks/evalDetect/useGetAllEvaluations';
import { setProjectEvaluation } from '../../store/projectSlice';

export const useFetchProjectEvaluations = () => {
  const dispatch = useDispatch();

  const getProjectEvaluationsMutation = useGetAllEvaluations({
    onSuccess(data) {
      dispatch(setProjectEvaluation(data));
    },
    onError(error) {
      console.log(`Error fetching evaluations: ${error}`);
    },
  });

  return getProjectEvaluationsMutation;
};
