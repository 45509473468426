import React from "react";
import { Box, Typography, Checkbox, FormControlLabel } from "@mui/material";
import { PrivateFeature } from "../../types";
import SelectButton from "../buttons/SelectButton";
import styles from "./css/PrivateFeaturesTable.module.css";

interface PrivateFeaturesTableProps {
  features: PrivateFeature[];
  setFeatures: React.Dispatch<React.SetStateAction<PrivateFeature[]>>;
}

const PrivateFeaturesTable: React.FC<PrivateFeaturesTableProps> = ({
  features,
  setFeatures,
}) => {
  const handleCheckboxChange = (index: number) => {
    setFeatures((prevFeatures) =>
      prevFeatures.map((feature, i) =>
        i === index ? { ...feature, selected: !feature.selected } : feature
      )
    );
  };

  const handleSelectChange = (index: number, value: string) => {
    setFeatures((prevFeatures) =>
      prevFeatures.map((feature, i) =>
        i === index ? { ...feature, value } : feature
      )
    );
  };

  return (
    <Box className={styles.container}>
      <Typography className={styles.tableTitle}>Private Features</Typography>
      <div className={styles.tableHeader}>
        <Typography className={styles.headerItem}>Private Feature</Typography>
        <Typography className={styles.headerItem}>Value</Typography>
      </div>
      <div className={styles.tableBody}>
        {features.map((feature, index) => (
          <div key={index} className={styles.tableRow}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={feature.selected || false}
                  onChange={() => handleCheckboxChange(index)}
                  className={styles.checkbox}
                />
              }
              label={
                <span className={styles.featureName}>{feature.name}</span>
              }
              className={styles.checkboxLabel}
            />
            <div className={styles.selectContainer}>
              {feature.is_categorical && (
                <SelectButton
                  data={feature.values}
                  selectedValue={feature.value}
                  updateFunction={(value: string) =>
                    handleSelectChange(index, value)
                  }
                  disabledState={!feature.selected}
                  isUpload={false}
                  className={styles.selection}
                  listClassName={styles.selectList}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default PrivateFeaturesTable;
