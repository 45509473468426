import { LoginData, LoginResponse } from "@/types";
import { FirebaseApp, initializeApp } from 'firebase/app';
// import { getAnalytics } from "firebase/analytics";
import {
    getAuth,
    signInWithEmailAndPassword,
    UserCredential,
    sendPasswordResetEmail,
    signOut,
    GoogleAuthProvider,
    signInWithPopup,
} from 'firebase/auth';
import { setToken } from "../store/userSlice";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp);

export const Login = async (data: LoginData, dispatch: any): Promise<LoginResponse> => {
    try {
        const userCredential: UserCredential = await signInWithEmailAndPassword(auth, data.email, data.password);
        const idToken = await userCredential.user.getIdToken();
        const refreshToken = userCredential.user.refreshToken;
        const localId = userCredential.user.uid;
        if (idToken && refreshToken) {
            dispatch(setToken({ token: idToken, refreshToken: refreshToken }));
        }
        return {
            idToken,
            refreshToken,
            localId,
        };
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const ResetPassword = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const Logout = async () => {
    signOut(auth);
    window.location.reload();
}

export const signInWithGoogle = async (dispatch: any): Promise<LoginResponse> => {
    const provider = new GoogleAuthProvider();
    try {
        const result = await signInWithPopup(auth, provider);
        const userCredential = result.user;
        const idToken = await userCredential.getIdToken();
        const refreshToken = userCredential.refreshToken;
        const localId = userCredential.uid;
        if (idToken && refreshToken) {
            dispatch(setToken({ token: idToken, refreshToken: refreshToken }));
        }
        return {
            idToken,
            refreshToken,
            localId,
        };
    } catch (err) {
        console.error(err);
        throw err;
    }
};
