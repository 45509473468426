import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Breadcrumbs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MainButton from "../buttons/MainButton";
import YesNoButton from "../buttons/YesNoButton";
import AttacksDefensesTable from "../tables/AttacksDefensesTable";
import styles from "./css/EvaluationForm.module.css";
import accordionStyles from "../tables/css/AttacksDefensesTable.module.css";
import { Attack, Defense, ValidationData } from "@/types";

const EvaluationForm: React.FC<{
  projectName: string;
  projectType: string;
  analysisData?: any;
  open: boolean;
  onClose: () => void;
  validation: ValidationData;
  setValidation: (validation: ValidationData) => void;
  handleRunEvaluation: () => void;
  isLoading: boolean;
  analysis: any;
  setAnalysis: (value: any) => void;
}> = ({
  projectName,
  open,
  onClose,
  projectType,
  validation,
  setValidation,
  handleRunEvaluation,
  isLoading,
  analysis,
  setAnalysis,
}) => {
  const [expanded, setExpanded] = useState<string[]>([
    "panel1",
    "panel2",
    "panel3",
  ]);

  const handleAnalysisChange = (key: string, value: any) => {
    if (
      key === "epsilon" ||
      key === "optimize_defence" ||
      key === "optimize_attacks"
    ) {
      setAnalysis((prev: any) => ({
        ...prev,
        analysis: {
          ...prev.analysis,
          [key]: value,
        },
      }));
    } else {
      setAnalysis((prev: any) => ({ ...prev, [key]: value }));
    }
  };

  const handleNumIterChange = (newItem: Attack) => {
    setValidation({
      ...validation,
      attacks: validation?.attacks?.map((attack) =>
        attack.name === newItem.name
          ? { ...attack, default_max_iter: newItem.default_max_iter }
          : attack
      ),
    });
  };

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded((prev) =>
        isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
      );
    };

  return (
    <>
      <Dialog open={open} onClose={() => {}} maxWidth="xl">
        <Box sx={{ p: "1rem 0rem 1rem 2rem" }}>
          <DialogTitle>
            <Typography>
              <Grid container spacing={1}>
                <div className={styles.top}>
                  <Breadcrumbs
                    className={`${
                      projectType === "me"
                        ? styles.me
                        : projectType === "de"
                        ? styles.de
                        : styles.be
                    }`}
                    separator="/"
                    aria-label="breadcrumbs"
                  >
                    <Typography fontSize="1.5rem" sx={{ color: "black" }}>
                      {projectName}
                    </Typography>
                    <Typography fontSize="1.5rem" sx={{ color: "#215efc" }}>
                      {"Run Analysis"}
                    </Typography>
                  </Breadcrumbs>
                  <div className={styles.closeButton}>
                    <IconButton onClick={onClose}>X</IconButton>
                  </div>
                </div>
              </Grid>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box className={styles.formContainer}>
              <AttacksDefensesTable
                type="attacks"
                data={validation?.attacks as Attack[]}
                selectedItems={analysis.attacks}
                handleSelectionChange={(selectedItems) =>
                  handleAnalysisChange("attacks", selectedItems)
                }
                handleNumIterationsChange={handleNumIterChange}
              />
              <AttacksDefensesTable
                type="defenses"
                data={validation?.defenses as Defense[]}
                selectedItems={analysis.defenses}
                handleSelectionChange={(selectedItems) =>
                  handleAnalysisChange("defenses", selectedItems)
                }
              />

              <Accordion
                className={accordionStyles.accordion}
                expanded={expanded.includes("panel2")}
                onChange={handleAccordionChange("panel2")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  className={accordionStyles.accordionSummary}
                >
                  <Typography className={accordionStyles.title}>
                    Hyperparameters Optimization
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={accordionStyles.accordionDetails}>
                  <Box className={styles.configGrid}>
                    <Box className={styles.configColumnPerform}>
                      <Typography className={styles.configLabel}>
                        Perform hyperparameters optimization for attacks?
                      </Typography>
                      <Typography className={styles.configLabel}>
                        Perform hyperparameters optimization for defenses?
                      </Typography>
                    </Box>
                    <Box className={styles.configColumnYesNo}>
                      <YesNoButton
                        handleChange={(value) => {
                          handleAnalysisChange(
                            "optimize_attacks",
                            value ? "yes" : "no"
                          );
                        }}
                      />
                      <YesNoButton
                        handleChange={(value) => {
                          handleAnalysisChange(
                            "optimize_defence",
                            value ? "yes" : "no"
                          );
                        }}
                      />
                    </Box>
                    <Box className={styles.configColumnEpsilon}>
                      <Typography className={styles.configLabel}>
                        Epsilon
                      </Typography>
                    </Box>
                    <Box className={styles.configColumn}>
                      <input
                        type="number"
                        min="0"
                        max="1"
                        step="0.01"
                        value={analysis.analysis.epsilon}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          if (value >= 0 && value <= 1) {
                            handleAnalysisChange("epsilon", value);
                          }
                        }}
                        className={styles.textInput}
                      />
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1rem",
                }}
              >
                <MainButton
                  theme="blue"
                  label="Run Analysis"
                  onClick={handleRunEvaluation}
                  isLoading={isLoading}
                  disabled={isLoading}
                />
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default EvaluationForm;
