import { useMutation } from '@tanstack/react-query'
import { deleteModel } from '../../api/configMngApi'
import { PostParam } from '@/types'

export function useDeleteModel(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: deleteModel,
        onSuccess,
        onError
    })
}