import React, { useEffect } from "react";
import { AppBar, Toolbar, Typography, ListItemButton, ListItemText, Grid } from "@mui/material";
import styles from "./css/ProjectsTab.module.css";

type ProjectType = "ME" | "DE" | "BE" | "LA";

interface MenuItem {
  text: string;
  type: ProjectType;
}

interface ProjectTabProps {
  selectedItem: string;
  setSelectedItem: (item: string) => void;
}

const ProjectTab: React.FC<ProjectTabProps> = ({ selectedItem, setSelectedItem }) => {
  const homeMenuItems: MenuItem[] = [
    { text: "Model Evaluation Projects", type: "ME" },
    { text: "Data Evaluation Projects", type: "DE" },
    { text: "Bias Evaluation Projects", type: "BE" },
    { text: "Last Analyses", type: "LA" },
  ];

  useEffect(() => {
    if (!selectedItem) {
      setSelectedItem("ME");
    }
  }, [selectedItem, setSelectedItem]);

  const handleColor = (type: string) => {
    let color;
    switch (type) {
      case 'ME':
        color = 'rgb(114, 46, 209)';
        break;
      case 'DE':
        color = 'rgb(21, 162, 223)';
        break;
      case 'BE':
        color = 'rgb(4, 201, 126)';
        break;
      default:
        color = 'black'; // Default color for LA
    }
    document.documentElement.style.setProperty('--border-bottom', color);
  };

  const getSelectedClass = (type: ProjectType) => {
    switch (type) {
      case "ME":
        return styles.selectedME;
      case "DE":
        return styles.selectedDE;
      case "BE":
        return styles.selectedBE;
      case "LA":
        return styles.selectedLA;
      default:
        return "";
    }
  };

  const onClick = (type: string) => {
    setSelectedItem(type);
    handleColor(type);
  }

  useEffect(()=>{
    handleColor(selectedItem);
  }, [selectedItem])

  return (
    <AppBar position="static" className={styles.subbarAppbar} elevation={0}>
      <Grid container spacing={1} paddingTop="1rem" paddingBottom="1rem" className={styles.subbarGrid}>
        <Toolbar className={styles.subbarToolbar}>
          <Typography variant="h6" className={styles.tabList}>
            {homeMenuItems.map((item) => (
              <ListItemButton
                className={`${styles.subbarListitembutton} ${selectedItem === item.type ? getSelectedClass(item.type) : ''}`}
                key={item.text}
                onClick={() => onClick(item.type)}
              >
                <ListItemText
                  className={`${styles.subbarListitemtext} ${selectedItem === item.type ? getSelectedClass(item.type) : ''}`}
                  primary={item.text}
                />
              </ListItemButton>
            ))}
          </Typography>
        </Toolbar>
      </Grid>
    </AppBar>
  );
};

export default ProjectTab;