import { useMutation } from '@tanstack/react-query'
import { getProject } from '../../api/projectsApi'
import { PostParam } from '@/types'

export function useGetProject(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: getProject,
        onSuccess,
        onError
    })
}