import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import bgIcon from '../../icons/CB_icon.svg';
import cyberIcon from '../../icons/Cyber_icon.svg';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import MainButton from '../buttons/MainButton';
import GoogleBanner from './GoogleBanner'; 
import styles from './css/NavBarTest.module.css'; 


const NavBar: React.FC = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const iconSize = isSmallScreen ? '48px' : '84px';
  const navBarHeight = isSmallScreen ? '80px' : '100px';

  const handleHelpClicked = () => {
    window.open('https://autodefenseml-documentation.readthedocs.io', '_blank');
  };

  return (
    <AppBar position="static" style={{ backgroundColor: 'white', color: 'black', height: navBarHeight }}>
      <Toolbar style={{ height: '100%' }}>
        <Box display="flex" alignItems="center" flexGrow={1}>
          <img src={bgIcon} alt="Logo 1" style={{ height: iconSize, marginRight: '16px' }} />
          <img src={cyberIcon} alt="Logo 2" style={{ height: iconSize, marginRight: '16px' }} />
        </Box>
        <Box className={styles.centered}>
          <Typography className={styles.auto}>Auto</Typography>
          <Typography className={styles.defense}>Defense</Typography>
          <Typography className={styles.ml}>ML</Typography>
        </Box>
        <Box display={{ xs: 'none', sm: 'flex' }} alignItems="center" marginRight="16px">
          <GoogleBanner />
        </Box>
        <Box display={{ xs: 'none', sm: 'flex' }}>
          <MainButton theme="blue" label="Help" IconComponent={WavingHandIcon} onClick={handleHelpClicked} />
        </Box>      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
