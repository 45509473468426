import { useDispatch } from 'react-redux';
import { useGetAllDetections } from '../../hooks/evalDetect/useGetAllDetections';
import { setProjectDetection } from '../../store/projectSlice';

export const useFetchProjectDetections = () => {
  const dispatch = useDispatch();

  const getProjectDetectionsMutation = useGetAllDetections({
    onSuccess(data) {
      dispatch(setProjectDetection(data));
    },
    onError(error) {
      console.log(`Error fetching detections: ${error}`);
    },
  });

  return getProjectDetectionsMutation;
};
