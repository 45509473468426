import { useMutation } from '@tanstack/react-query'
import { getDatasets } from '../../api/configMngApi'
import { PostParam } from '@/types'

export function useGetDatasets(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: getDatasets,
        onSuccess,
        onError
    })
}