import { useMutation } from '@tanstack/react-query'
import { updateModel } from '../../api/configMngApi'
import { PostParam } from '@/types'

export function useUpdateModel(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: updateModel,
        onSuccess,
        onError
    })
}