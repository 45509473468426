import { useMutation } from '@tanstack/react-query'
import { generateURL } from '../../api/configMngApi'
import { PostParam } from '@/types'

export function useGenerateURL(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: generateURL,
        onSuccess,
        onError
    })
}