import React, { useState, useEffect, useRef } from "react";
import { Tabs, Tab } from "@mui/material";
import AllAnalysesTable from "../tables/AllAnalysesTable";
import styles from "./css/Resources.module.css";
import { EvaluationData, DetectionData } from "@/types";
import { useStopRun } from "../../hooks/evalDetect/useStopRun";
import { useFetchProjectEvaluations } from "../../utils/projectUtils/getAllEvaluations";
import { useFetchProjectDetections } from "../../utils/projectUtils/getAllDetections";
import { useDispatch } from "react-redux";
import { setMessage, setShowMessage } from "../../store/messageSlice";
import { useCheckEvalStatus } from "../../hooks/evalDetect/useCheckEvalStatus";
import { useLocation } from "react-router-dom";

interface AllAnalysesResourcesProps {
  evaluationsData: EvaluationData[];
  detectionsData: DetectionData[];
  projectType: string;
  projectId: string;
  setTablesChange: (value: boolean) => void;
}

const parseDateString = (dateString: string): Date => {
  const [day, month, yearTime] = dateString.split("/");
  const [year, time] = yearTime.split(" ");
  const monthIndex = new Date(`${month} 1`).getMonth(); // Get the month index from the month name
  const [hours, minutes] = time.split(":").map(Number);
  return new Date(Number(year), monthIndex, Number(day), hours, minutes);
};

const sortDataByStartTime = <T extends { start_time: string }>(
  data: T[]
): T[] => {
  return data
    .slice()
    .sort(
      (a, b) =>
        parseDateString(b.start_time).getTime() -
        parseDateString(a.start_time).getTime()
    );
};

const Resources: React.FC<AllAnalysesResourcesProps> = ({
  evaluationsData,
  detectionsData,
  projectType,
  projectId,
  setTablesChange
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const getEvaluations = useFetchProjectEvaluations();
  const getDetections = useFetchProjectDetections();
  const [foundOne, setFoundOne] = useState(false);
  const [runningId, setRunningId] = useState<string | null>(null)

  useEffect(() => {
    setActiveTab(0);
  }, [projectType]);

  const checkEvaluationStatusMutation = useCheckEvalStatus({
    onSuccess(data) {
      getEvaluations.mutate({ project_id: projectId });
      if (data.evaluation.status.toLowerCase() === "running" && location.pathname !== "/home") {
        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        // Set new timeout and store the reference
        timeoutRef.current = setTimeout(() => {
          checkEvaluateStatus(data.evaluation.id);
        }, 5000);
      } else {
        setRunningId(null);
      }
    },
    onError(error) {
      getEvaluations.mutate({ project_id: projectId });
      if (runningId) {
        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        // Set new timeout and store the reference
        timeoutRef.current = setTimeout(() => {
          checkEvaluateStatus(runningId);
        }, 5000);
      }
    },
  });

  useEffect(() => {
    // Cleanup function
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const checkEvaluateStatus = (evaluation_id: string) => {
    if(location.pathname !== "/home"){
      checkEvaluationStatusMutation.mutate({
        project_id: projectId,
        eval_id: evaluation_id,
      });
    }
  };

  useEffect(() => {
    if (!foundOne) {
      const running = evaluationsData.find(
        (ev) => ev.status.toLowerCase() === "running"
      );
      if (running) {
        setRunningId(running.id)
        checkEvaluateStatus(running.id);
        setFoundOne(true);
      }
    }
  }, [evaluationsData]);

  const stopRunMutation = useStopRun({
    onSuccess(data) {
      getEvaluations.mutate({ project_id: projectId });
      getDetections.mutate({ project_id: projectId });
    },
    onError(error) {
      dispatch(setMessage(error.response.data.message));
      dispatch(setShowMessage(true));
    },
  });

  const handleStop = (run_id: string, type: string) => {
    let body;
    if (type === "evaluation") {
      body = { project_id: projectId, eval_id: run_id };
    } else {
      body = { project_id: projectId, detection_id: run_id };
    }
    stopRunMutation.mutate(body);
  };

  const sortedEvaluations = sortDataByStartTime(evaluationsData || []);
  const sortedDetections = sortDataByStartTime(detectionsData || []);

  return (
    <div className={styles.resourcesContainer}>
      {projectType === "BE" ? (
        <>
          <div className={styles.tabsContainer}>
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => {setActiveTab(newValue); setTablesChange(true)}}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Evaluation Data" className={styles.tab} />
              <Tab label="Detection Data" className={styles.tab} />
            </Tabs>
          </div>
          {activeTab === 0 && (
            <AllAnalysesTable
              evaluations={sortedEvaluations || []}
              type="BE"
              data="evaluation"
              onStop={(run_id) => handleStop(run_id, "evaluation")}
            />
          )}
          {activeTab === 1 && (
            <AllAnalysesTable
              detections={sortedDetections || []}
              type="BE"
              data="detection"
              onStop={(run_id) => handleStop(run_id, "detection")}
            />
          )}
        </>
      ) : (
        <AllAnalysesTable
          evaluations={sortedEvaluations || []}
          type={(projectType as "ME") || "DE"}
          data="evaluation"
          onStop={(run_id) => handleStop(run_id, "evaluation")}
        />
      )}
    </div>
  );
};

export default Resources;
