import { CreatModel, Model, Project, CreateDataset, Dataset, UpdateModel, UpdateDataset, URL } from "@/types";
import { PostRequest, PostRequestFormData } from "./requests";

// Model requests
export const createModel = async (data: CreatModel) => {
    try {
        return await PostRequestFormData("/configuration/create_model", data);
    } catch (error) {
        console.log('Error in createModel:', error);
        throw error;
    }
}

export const getModel = async (data: Model) => {
    try {
        return await PostRequest("/configuration/get_model", data);
    } catch (error) {
        console.log('Error in getModel:', error);
        throw error;
    }
}

export const getModels = async (data: Project) => {
    try {
        return await PostRequest("/configuration/get_models", data);
    } catch (error) {
        console.log('Error in getModels:', error);
        throw error;
    }
}

export const deleteModel = async (data: Model) => {
    try {
        return await PostRequest("/configuration/delete_model", data);
    } catch (error) {
        console.log('Error in deleteModel:', error);
        throw error;
    }
}

export const updateModel = async (data: UpdateModel) => {
    try {
        return await PostRequestFormData("/configuration/update_model", data);
    } catch (error) {
        console.log('Error in updateModel:', error);
        throw error;
    }
}

// Dataset Requests
export const createDataset = async (data: CreateDataset) => {
    try {
        return await PostRequestFormData("/configuration/create_dataset", data);
    } catch (error) {
        console.log('Error in createDataset:', error);
        throw error;
    }
}

export const getDataset = async (data: Dataset) => {
    try {
        return await PostRequest("/configuration/get_dataset", data);
    } catch (error) {
        console.log('Error in getDataset:', error);
        throw error;
    }
}

export const getDatasets = async (data: Project) => {
    try {
        return await PostRequest("/configuration/get_datasets", data);
    } catch (error) {
        console.log('Error in getDatasets:', error);
        throw error;
    }
}

export const deleteDataset = async (data: Dataset) => {
    try {
        return await PostRequest("/configuration/delete_dataset", data);
    } catch (error) {
        console.log('Error in deleteDataset:', error);
        throw error;
    }
}

export const updateDataset = async (data: UpdateDataset) => {
    try {
        return await PostRequestFormData("/configuration/update_dataset", data);
    } catch (error) {
        console.log('Error in updateDataset:', error);
        throw error;
    }
}


export const generateURL = async (data: URL) => {
    try {
        return await PostRequest("/configuration/generate_signed_url", data);
    } catch (error) {
        console.log('Error in generate URL:', error);
        throw error;
    }
}
