import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './css/Breadcrumb.module.css';
import { useDispatch } from 'react-redux';
import { clearCurrentProject } from '../../store/projectSlice';

interface BreadcrumbProps {
  projectName: string;
  projectType: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ projectName, projectType }) => {
  const navigate = useNavigate();
  const dispacth = useDispatch();

  const handleHomeClick = () => {
      dispacth(clearCurrentProject());
      navigate('/home');
  };

  return (
    <nav className={`${styles.breadcrumb} ${projectType === "me" ? styles.me: projectType === "de"? styles.de: styles.be}`}>
      <span
        className={`${styles.breadcrumbItem} ${styles.active}`}
        onClick={handleHomeClick}
        style={{ cursor: 'pointer' }}
      >
        Home
      </span>
      <span className={styles.breadcrumbSeparator}>/</span>
      <span className={`${styles.breadcrumbItem} ${styles.disabled}`}>
        {projectName}
      </span>
    </nav>
  );
};

export default Breadcrumb;
