import { useMutation } from '@tanstack/react-query'
import { deleteProject } from '../../api/projectsApi'
import { PostParam } from '@/types'

export function useDeleteProject(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: deleteProject,
        onSuccess,
        onError
    })
}