import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Attack, Defense, SelectedAttacks } from "@/types";
import { ColumnsSetting } from "./settings";
import styles from "./css/AttacksDefensesTable.module.css";

type SelectedItem = string | { name: string; numIterations?: number ; class_name: string };

interface AttacksDefensesTableProps {
  type: "attacks" | "defenses";
  data: Attack[] | Defense[];
  selectedItems: (string | SelectedAttacks)[];
  handleSelectionChange: (selectedItems: SelectedItem[]) => void;
  handleNumIterationsChange?: (updatedItem: Attack) => void;
}

const isAttack = (item: Attack | Defense): item is Attack => {
  return (item as Attack).default_max_iter !== undefined;
};

const AttacksDefensesTable: React.FC<AttacksDefensesTableProps> = ({
  type,
  data,
  selectedItems,
  handleSelectionChange,
  handleNumIterationsChange = () => {},
}) => {
  const [selected, setSelected] = useState<
    (string | { name: string; numIterations?: number; class_name: string })[]
  >([]);

  const [originalMaxIters, setOriginalMaxIters] = useState<{[key: string]: number}>({});

  useEffect(() => {
    setSelected(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (type === "attacks") {
      const maxIters = (data as Attack[]).reduce((acc, attack) => {
        if (attack.has_max_iter) {
          acc[attack.name] = attack.default_max_iter;
        }
        return acc;
      }, {} as {[key: string]: number});
      setOriginalMaxIters(maxIters);
    }
  }, [])

  const handleSelect = (name: string, class_name: string, numIterations: number | null) => {
    const currentIndex = selected.findIndex((item) =>
      typeof item === "string" ? item === class_name : item.name === name
    );
    const newSelected = [...selected];
  
    if (currentIndex === -1) {
      if (type === "attacks") {
        newSelected.push({
          class_name,
          name,
          numIterations: numIterations ?? undefined,
        });
      } else {
        newSelected.push({ class_name, name });
      }
    } else {
      newSelected.splice(currentIndex, 1);
    }
  
    setSelected(newSelected);
    handleSelectionChange(newSelected);
  };
  
  

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((item) =>
        isAttack(item)
          ? { name: item.name, numIterations: item.has_max_iter? item.default_max_iter: undefined, class_name: item.class_name }
          : {name: item.name, class_name: item.class_name}
      );
      setSelected(newSelecteds);
      handleSelectionChange(newSelecteds);
    } else {
      setSelected([]);
      handleSelectionChange([]);
    }
  };

  const handleNumIterationsChangeLocal = (
    name: string,
    class_name: string,
    numIterations?: number
  ) => {
    console.log(numIterations);
    const newSelected = selected.map((item) =>
      typeof item === "string"
        ? item === name
          ? { name, numIterations, class_name }
          : item
        : item.name === name
        ? { ...item, numIterations }
        : item
    );
    setSelected(newSelected);
    handleSelectionChange(newSelected);
  };

  const isSelected = (name: string) =>
    selected.some((item) =>
      typeof item === "string" ? item === name : item.name === name
    );

  const { attacks, defenses } = ColumnsSetting();
  const columns = type === "attacks" ? attacks : defenses;

  return (
    <Box className={styles.container}>
      <Accordion className={styles.accordion} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={styles.accordionSummary}
        >
          <Typography className={styles.title}>
            {type.charAt(0).toUpperCase() + type.slice(1)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <Typography className={styles.description}>
            Choosing the {type}
          </Typography>
          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="checkbox"
                    className={`${styles.checkboxHead} ${styles.checkbox} ${styles.minWidthCell}`}
                  >
                    <Checkbox
                      indeterminate={
                        selected.length > 0 && selected.length < data.length
                      }
                      checked={
                        data.length > 0 && selected.length === data.length
                      }
                      onChange={handleSelectAll}
                      color="primary"
                      classes={{
                        root: styles.checkbox,
                        checked: styles.checked,
                      }}
                    />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className={styles.headerCell}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow key={item.name} selected={isSelected(item.name)}>
                    <TableCell
                      padding="checkbox"
                      className={`${styles.checkbox} ${styles.minWidthCell}`}
                    >
                      <Checkbox
                        checked={isSelected(item.name)}
                        onChange={() =>
                          handleSelect(
                            item.name,
                            item.class_name,
                            isAttack(item) && item.has_max_iter ? item.default_max_iter : null
                          )
                        }
                        color="primary"
                        classes={{
                          root: styles.checkbox,
                          checked: styles.checked,
                        }}
                      />
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        className={styles.data}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.id === "default_max_iter" && type === "attacks"
                          ? (item as Attack)?.has_max_iter && ( 
                            <TextField
                            className={styles.textFiled}
                            type="number"
                            value={(item as Attack).default_max_iter || 0}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value, 10);
                              const originalMaxIter = originalMaxIters[item.name];
                              if (!isNaN(newValue) && newValue <= originalMaxIter && newValue >= 0) {
                                handleNumIterationsChangeLocal(
                                  item.name,
                                  item.class_name,
                                  newValue
                                );
                                handleNumIterationsChange({
                                  ...(item as Attack),
                                  default_max_iter: newValue,
                                });
                              }
                            }}
                            inputProps={{ min: 0, max: originalMaxIters[item.name] }}
                          />
                            )
                          : item[column.id as keyof (Attack | Defense)]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AttacksDefensesTable;
