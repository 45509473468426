import React, { useState, useEffect, useRef, useCallback } from "react";
import { Box, Grid, Typography } from "@mui/material";
import MainButton from "../../buttons/MainButton";
import { useRunValidation } from "../../../hooks/validation/useRunValidation";
import { useCheckStatus } from "../../../hooks/validation/useCheckStatus";
import styles from "../css/NewAnalysis.module.css";
import { ValidationData } from "@/types";

interface ValidationSectionProps {
  projectId: string;
  projectType: string;
  modelSaved: boolean;
  datasetSaved: boolean;
  validation: ValidationData;
  setValidationDone: (value: boolean) => void;
  setValidation: (validation: ValidationData) => void;
  setStep: (value: number) => void;
}

const ValidationSection: React.FC<ValidationSectionProps> = ({
  projectId,
  projectType,
  modelSaved,
  datasetSaved,
  validation,
  setValidationDone,
  setValidation,
  setStep,
}) => {
  const [validationMessage, setValidationMessage] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [disableValidation, setDisableValidation] = useState(true);
  const [validationLoading, setValidationLoading] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (projectType === "me") {
      setDisableValidation(!(modelSaved && datasetSaved));
    } else {
      setDisableValidation(!datasetSaved);
    }
  }, [modelSaved, datasetSaved, projectType]);

  const checkValidationStatus = useCallback(
    (validation_id: string) => {
      checkValidationStatusMutation.mutate({
        project_id: projectId,
        validation_id,
      });
    },
    [projectId]
  );

  const runValidation = useCallback(
    (validation_id: string) => {
      runValidationMutation.mutate({ project_id: projectId, validation_id });
    },
    [projectId]
  );

  const checkValidationStatusMutation = useCheckStatus({
    onSuccess(data) {
      if (data.validation.status.toLowerCase() === "running") {
        // Clear any existing timeout
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        // Set new timeout and store the reference
        timeoutRef.current = setTimeout(() => {
          checkValidationStatus(data.validation.id);
        }, 5000);
      } else {
        let message = data.message;
        console.log(message);
        const statusDone = data.validation.status.toLowerCase() === "done";
        if (statusDone) {
          message = data.validation?.error || data.message;
        }
        console.log(statusDone);
        setValidationMessage(message);
        setValidationError(!statusDone);
        setValidationDone(statusDone);
        setValidation(data.validation);
        setValidationLoading(false);
        setStep(1);
      }
    },
    onError(error) {
      setValidationError(true);
      setValidationMessage(error.response.data.message);
      setValidationLoading(false);
      setValidationDone(false);
    },
  });

  useEffect(() => {
    // Cleanup function
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const runValidationMutation = useRunValidation({
    onSuccess(data) {
      setValidationError(false);
      setValidationMessage("");
      // setValidation(data.validation);
      checkValidationStatus(data.validation_id);
    },
    onError(error) {
      const uploaded = error.response.data.uploaded
      if (uploaded !== undefined && uploaded === "false") {
        timeoutRef.current = setTimeout(() => {
          runValidation(validation.id as string);
        }, 5000);
      } else {
        setValidationError(true);
        setValidationMessage(error.response.data.message);
        setValidationLoading(false);
        setValidationDone(false);
      }
    },
  });

  const handleValidation = () => {
    setValidationLoading(true);
    setValidationMessage("");
    runValidationMutation.mutate({
      project_id: projectId,
      validation_id: validation.id,
    });
  };

  return (
    <>
      <Box className={styles.validationContainer}>
        <Typography className={styles.validationTitle}>Validate</Typography>
        <Box className={styles.validationButtonContainer}>
          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={1} md={1} lg={10}>
              <MainButton
                label="Run Validation"
                theme="blue"
                className={styles.button}
                onClick={handleValidation}
                disabled={validationLoading || disableValidation}
                isLoading={validationLoading}
              />
            </Grid>
            <Grid item xs={1} md={1} lg={5} className={styles.validationAlert}>
              <Typography
                className={`${validationMessage ? styles.showMessage : ""} ${
                  styles.message
                } ${validationError ? styles.errorMessage : ""}`}
              >
                {validationMessage}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ValidationSection;
