import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavBarTest from '../components/bars/NavBarTest';


const Layout: React.FC = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== '/' && <NavBarTest />}
      <Outlet />
    </>
  );
};

export default Layout;
