import { BiUpload } from "react-icons/bi";
import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Typography } from "@mui/material";
import MainButton from "../buttons/MainButton";
import styles from "./css/NewAnalysis.module.css";
import ModelSection from "./newAnalysisData/ModelSection";
import DatasetSection from "./newAnalysisData/DatasetSection";
import BiasSection from "./newAnalysisData/BiasSection";
import ValidationSection from "./newAnalysisData/ValidationSection";
import EvaluationSection from "./newAnalysisData/EvaluationSection";
import AnalysisInProgressMessage from "../assets/AnalysisInProgressMessage";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { ValidationData } from "@/types";
import { useUploadRequirements } from "../../hooks/validation/useAploadRequirements";
import { setShowMessage, setMessage } from "../../store/messageSlice";
import { useDispatch } from "react-redux";

interface NewAnalysisProps {
  projectType: "me" | "de" | "be";
  projectId: string;
  projectName: string;
  setSelectedTab: (tab: string) => void;
}

const initialValidationData: ValidationData = {
  id: null,
  project_id: "",
  project_type: "",
  user_id: "",
  job_id: "",
  status: "",
  dataset_id: "",
  attacks: [],
  defenses: [],
  features: [],
};

const NewAnalysis: React.FC<NewAnalysisProps> = ({
  projectType,
  projectId,
  projectName,
  setSelectedTab,
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const evaluations = useSelector(
    (state: RootState) => state.project.projectEvaluations
  );
  const [runningEvaluation, setRunningEvaluation] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [validationDone, setValidationDone] = useState(false);
  const [continueDisable, setContinueDisabled] = useState(true);
  const [validation, setValidation] = useState<ValidationData>(
    initialValidationData
  );
  const [modelSaved, setModelSaved] = useState(false);
  const [datasetSaved, setDatasetSaved] = useState(false);
  const [reqSaved, setReqSaved] = useState(false);
  const [reqLoading, setReqLoading] = useState(false);
  const [framework, setFramework] = useState("");
  // const [pythonReq, setPythonReq] = useState<uploadReq>({
  //   project_id: projectId,
  //   req: null,
  //   req_name: "",
  // });
  const [step, setStep] = useState(0);

  const uploadRequirmenets = useUploadRequirements({
    onSuccess(data) {
      setReqSaved(true);
      setReqLoading(false);
      setValidation(
        (prevData) =>
          ({
            ...prevData,
            id: data.validation_id,
          } as ValidationData)
      );
    },
    onError(error) {
      setReqSaved(false);
      setReqLoading(false);
      setValidation(
        (prevData) =>
          ({
            ...prevData,
            id: null,
          } as ValidationData)
      );
      dispatch(setMessage("Failed to upload python requirements file."));
      dispatch(setShowMessage(true));
    },
  });

  useEffect(() => {
    const hasRunningEvaluation = evaluations?.some(
      (evaluation) => evaluation.status.toLowerCase() === "running"
    );
    setRunningEvaluation(!!hasRunningEvaluation);
  }, [evaluations]);

  useEffect(() => {
    setContinueDisabled(!validationDone);
  }, [validationDone]);

  const handleUploadPythonReq = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    event.target.value = "";
    if (file) {
      const fileExtension = file?.name.split(".").pop();
      if (fileExtension !== "txt") {
        dispatch(setMessage("Python requirements file can be from type .txt only."));
        dispatch(setShowMessage(true));
      } else {
        const body = {
          project_id: projectId,
          req: file,
          req_name: file.name,
        };
        setReqLoading(true);
        uploadRequirmenets.mutate(body);
      }
    }
  };

  return (
    <Box className={styles.newAnalysisWrapper}>
      {runningEvaluation && <AnalysisInProgressMessage />}
      <Box className={styles.uploadFilesContainer}>
        <Typography className={styles.uploadFilesTitle}>
          Upload Files
        </Typography>
        <Box className={styles.buttonContainer}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            {projectType === "me" && (
              <ModelSection
                setFramework={setFramework}
                modelSaved={modelSaved}
                setModelSaved={setModelSaved}
                projectId={projectId}
                saveLoading={saveLoading}
                setSaveLoading={setSaveLoading}
                runningEval={runningEvaluation}
              />
            )}
            <DatasetSection
              framework={framework}
              projectType={projectType}
              datasetSaved={datasetSaved}
              setDatasetSaved={setDatasetSaved}
              projectId={projectId}
              saveLoading={saveLoading}
              setSaveLoading={setSaveLoading}
              runningEval={runningEvaluation}
            />
            {projectType === "me" && (
              <Grid item xs={2} md={2} lg={2}>
                <Box className={styles.buttonBox}>
                  <MainButton
                    label="python requirements"
                    theme={reqSaved ? "white" : "black"}
                    className={`${styles.button} ${styles.requirements}`}
                    onClick={() => {
                      fileInputRef.current?.click();
                    }}
                    IconComponent={BiUpload}
                    isLoading={reqLoading}
                    disabled={reqLoading}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleUploadPythonReq}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <ValidationSection
        setStep={setStep}
        modelSaved={modelSaved}
        datasetSaved={datasetSaved}
        validation={validation}
        setValidation={setValidation}
        projectId={projectId}
        projectType={projectType}
        setValidationDone={setValidationDone}
      />
      <BiasSection
        setTab={setSelectedTab}
        step={step}
        setStep={setStep}
        projectId={projectId}
        projectType={projectType}
        validationDone={validationDone}
        validation={validation as ValidationData}
      />
      <EvaluationSection
        setSelectedTab={setSelectedTab}
        setValidation={setValidation}
        validation={validation as ValidationData}
        projectId={projectId}
        projectType={projectType}
        projectName={projectName}
        continueDisable={continueDisable}
      />
    </Box>
  );
};

export default NewAnalysis;
