import { useMutation } from '@tanstack/react-query'
import { getByFbUID } from '../../api/userApi';
import { GetByFbUID, PostParam, UserData } from '@/types'

export function useGetByFbUID(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation<UserData, Error, GetByFbUID>({
        mutationFn: getByFbUID,
        onSuccess,
        onError
    })
}