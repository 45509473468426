import { GetByFbUID, UserData } from "@/types";
import { PostRequest } from "./requests";

export const getByFbUID = async (data: GetByFbUID) => {
    try {
        return await PostRequest<UserData>("/user/get_by_fb_uid", data);
    } catch (error) {
        console.log('Error in getByFbUID:', error);
        throw error;
    }
}