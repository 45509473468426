import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from '../src/routes/Layout';
import ProjectTypeGuard from './routes/ProtectedProjectType';
import ProtectedRoute from './routes/ProtectedRoute';
import ProjectPage from './pages/ProjectPage';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/404Page'
import AlertPopup from './components/assets/AlertPopup';
// const ProjectPage = lazy(() => import('./pages/ProjectPage'));
// const LoginPage = lazy(() => import('./pages/LoginPage'));
// const HomePage = lazy(() => import('./pages/HomePage'));


const App = () => {
  return (
    <>
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route element={<ProtectedRoute/>}> 
        <Route element={<Layout/>}>
        <Route element={<ProjectTypeGuard />}>
            <Route path="/:projectType/:projectId" element={<ProjectPage key={"/:projectId"}/>} />
          </Route>
          <Route path="/home" element={<HomePage/>}></Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage/>}></Route>
  </Routes>
   <AlertPopup />
   </>
  );
};

export default App;
