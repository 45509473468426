import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import ResourcesTable from "../tables/ResourcesTable";
import styles from "./css/Resources.module.css";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { ModelData, DatasetData } from "@/types";
import ConfirmPopup from "../../components/assets/ConfirmPopup";
import { useDeleteModel } from "../../hooks/configMng/useDeleteModel";
import { useFetchProjectModels } from "../../utils/projectUtils/getAllModels";
import { useFetchProjectDatasets } from "../../utils/projectUtils/getAllDatasets";
import { useDeleteDataset } from "../../hooks/configMng/useDeleteDataset";


interface ProjectResourcesProps {
  project_id: string;
  setTablesChange: (value: boolean) => void;
}

const Resources: React.FC<ProjectResourcesProps> = ({ project_id, setTablesChange }) => {
  const projectModels = useSelector<RootState, ModelData[] | null>(
    (state) => state.project.projectModels
  );
  const projectDatasets = useSelector<RootState, DatasetData[] | null>(
    (state) => state.project.projectDatasets
  );
  const [activeTab, setActiveTab] = useState(0);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resourceIdToDelete, setResourceIdToDelete] = useState<string | null>(null);
  const [resourceTypeToDelete, setResourceTypeToDelete] = useState<string | null>(null);

  const getModels = useFetchProjectModels();
  const getDatasets = useFetchProjectDatasets();

  const handleCancelDelete = () => {
    setConfirmDeleteOpen(false);
    setResourceIdToDelete(null);
    setResourceTypeToDelete(null);
  };

  const handleDeleteResource = (resourceId: string, resourceType: string) => {
    setResourceIdToDelete(resourceId);
    setResourceTypeToDelete(resourceType);
    setConfirmDeleteOpen(true);
  };

  const deleteModelMutation = useDeleteModel({
    onSuccess(data) {
      getModels.mutate({ project_id });
      setIsLoading(false);
      setConfirmDeleteOpen(false);
      setResourceIdToDelete(null);
      setResourceTypeToDelete(null);
    },
    onError(error) {
      console.log(`Error deleting model: ${error}`);
      setIsLoading(false);
      setConfirmDeleteOpen(false);
      setResourceIdToDelete(null);
      setResourceTypeToDelete(null);
    },
  });

  const deleteDatasetMutation = useDeleteDataset({
    onSuccess(data) {
      getDatasets.mutate({ project_id });
      setIsLoading(false);
      setConfirmDeleteOpen(false);
      setResourceIdToDelete(null);
      setResourceTypeToDelete(null);
    },
    onError(error) {
      console.log(`Error deleting dataset: ${error}`);
      setIsLoading(false);
      setConfirmDeleteOpen(false);
      setResourceIdToDelete(null);
      setResourceTypeToDelete(null);
    },
  });

  const handleConfirmDelete = () => {
    if (resourceTypeToDelete === "model" && resourceIdToDelete) {
      setIsLoading(true);
      deleteModelMutation.mutate({ model_id: resourceIdToDelete, project_id });
    } else if (resourceTypeToDelete === "dataset" && resourceIdToDelete) {
      setIsLoading(true);
      deleteDatasetMutation.mutate({ dataset_id: resourceIdToDelete, project_id });
    }
  };

  return (
    <div className={styles.resourcesContainer}>
      <div className={styles.tabsContainer}>
        <ConfirmPopup
          open={confirmDeleteOpen}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          title="Confirm Delete"
          content={`Are you sure you want to delete this ${resourceTypeToDelete}? This action cannot be undone.`}
          isLoading={isLoading}
        />
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => {setActiveTab(newValue); setTablesChange(true)}}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Model Data" className={styles.tab} />
          <Tab label="Dataset Data" className={styles.tab} />
        </Tabs>
      </div>
      {activeTab === 0 && (
        <ResourcesTable
          type="model"
          data={projectModels || []}
          onDelete={(modelId) => handleDeleteResource(modelId, "model")}
        />
      )}
      {activeTab === 1 && (
        <ResourcesTable
          type="dataset"
          data={projectDatasets || []}
          onDelete={(datasetId) => handleDeleteResource(datasetId, "dataset")}
        />
      )}
    </div>
  );
};

export default Resources;