import React, { useMemo } from "react";
import {
  MaterialReactTable,
  MRT_ColumnDef,
  MRT_Cell,
} from "material-react-table";
import { Box, Button, Tooltip } from "@mui/material";
import DownloadButton from "../buttons/DownloadButton";
import { ModelData, DatasetData } from "@/types";
import styles from "./css/AllAnalysesTable.module.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { truncateText } from "../../utils/truncateText";

interface TableProps {
  type: "model" | "dataset";
  data: ModelData[] | DatasetData[];
  onDelete: (name: string) => void;
}

const ResourcesTable: React.FC<TableProps> = ({ type, data, onDelete }) => {
  const handleText = (cell: MRT_Cell<any, unknown>) => {
    const text = cell.getValue<string>();
    const truncate = truncateText(text, 16);
    if( truncate !== text){
      return (
        <Tooltip title={cell.getValue<string>()} arrow>
          <span>{truncate}</span>
        </Tooltip>
      );
    }
    return text;
  };
  const columns: MRT_ColumnDef<any>[] = useMemo(
    () =>
      type === "model"
        ? [
            {
              accessorKey: "model_parameters_name",
              header: "Parameters Name",
              size: 150,
              Cell: ({ cell }) => (
                handleText(cell)
              ),
            },
            {
              accessorKey: "model_definition_name",
              header: "Definition Name",
              size: 150,
              Cell: ({ cell }) => (
                handleText(cell)
              ),
            },
            {
              accessorKey: "loss_name",
              header: "Loss Name",
              size: 100,
              Cell: ({ cell }) => (
                handleText(cell)
              ),
            },
            {
              accessorKey: "model_parameters_url",
              header: "Parameters URL",
              size: 150,
              Cell: ({ cell }) => (
                <DownloadButton
                  type="url"
                  content={cell.getValue<string>()}
                  filename="model_parameters"
                />
              ),
            },
            {
              accessorKey: "model_definition_url",
              header: "Definition URL",
              size: 150,
              Cell: ({ cell }) =>
                cell.getValue<string>() ? (
                  <DownloadButton
                    type="url"
                    content={cell.getValue<string>()}
                    filename="model_definition"
                  />
                ) : (
                  ""
                ),
            },
            {
              accessorKey: "loss_url",
              header: "Loss URL",
              size: 100,
              Cell: ({ cell }) =>
                cell.getValue<string>() ? (
                  <DownloadButton
                    type="url"
                    content={cell.getValue<string>()}
                    filename="loss"
                  />
                ) : (
                  ""
                ),
            },
            {
              header: "Delete",
              size: 50,
              Cell: ({ row }) => (
                <Button onClick={() => onDelete(row.original.id)}>
                  <DeleteIcon />
                </Button>
              ),
            },
          ]
        : [
            {
              accessorKey: "dataloader_name",
              header: "Dataloader Name",
              size: 218,
              Cell: ({ cell }) => (
                handleText(cell)
              ),
            },
            {
              accessorKey: "testset_name",
              header: "Testset Name",
              size: 218,
              Cell: ({ cell }) => (
                handleText(cell)
              ),
            },
            {
              accessorKey: "dataloader_url",
              header: "Dataloader URL",
              size: 219,
              Cell: ({ cell }) =>
                cell.getValue<string>() ? (
                  <DownloadButton
                    type="url"
                    content={cell.getValue<string>()}
                    filename="dataloader"
                  />
                ) : (
                  ""
                ),
            },
            {
              accessorKey: "testset_url",
              header: "Testset URL",
              size: 219,
              Cell: ({ cell }) =>
                cell.getValue<string>() ? (
                  <DownloadButton
                    type="url"
                    content={cell.getValue<string>()}
                    filename="testset"
                  />
                ) : (
                  ""
                ),
            },
            {
              header: "Delete",
              size: 50,
              Cell: ({ row }) => (
                <Button onClick={() => onDelete(row.original.id)}>
                  <DeleteIcon />
                </Button>
              ),
            },
          ],
    [type, onDelete]
  );

  return (
    <Box className={styles.container} sx={{ boxShadow: 0 }}>
      <MaterialReactTable
        columns={columns}
        data={data}
        enableColumnResizing={false}
        enableBottomToolbar={false}
        enableStickyHeader
        enableMultiRowSelection={false}
        enablePagination={false}
        enableColumnActions={false}
        enableSorting={false}
        enableGlobalFilter={false}
        enableFilters={false}
        enableTopToolbar={false}
        muiTableProps={{
          className: styles.table,
        }}
        muiTableFooterProps={{
          className: styles.tableFooter,
        }}
        muiTableContainerProps={{
          className: styles.tableContainer,
        }}
        muiTableHeadCellProps={{
          className: styles.tableHeadCell,
        }}
        muiTableHeadRowProps={{
          className: styles.tableHeadRow,
        }}
        muiTablePaperProps={{
          className: styles.tablePaper,
        }}
        muiTableBodyRowProps={{
          className: styles.tableBodyRow,
        }}
        state={{
          isLoading: false,
          showAlertBanner: false,
          showProgressBars: false,
        }}
      />
    </Box>
  );
};

export default ResourcesTable;
