import { useMutation } from '@tanstack/react-query'
import { uploadRequirements } from '../../api/validationApi'
import { PostParam } from '@/types'

export function useUploadRequirements(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: uploadRequirements,
        onSuccess,
        onError
    })
}