import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from './css/AnalysisInProgressMessage.module.css';

const AnalysisInProgressMessage: React.FC = () => {
  return (
    <Box className={styles.messageContainer}>
      <Typography className={styles.messageText}>
        Analysis process already running
      </Typography>
    </Box>
  );
};

export default AnalysisInProgressMessage;
