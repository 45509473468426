import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid } from "@mui/material";
import MainButton from "../components/buttons/MainButton";
import styles from "./css/404Page.module.css";
import bgIcon from "../icons/CB_icon.svg";
import cyberIcon from "../icons/Cyber_icon.svg";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/home");
  }

  return (
    <Box className={styles.container}>
      <Grid container spacing={1} direction="row" position="relative" overflow="hidden" className={styles.notFoundBar}>
        <Grid item xs="auto" md="auto" lg="auto">
          <img src={bgIcon} alt="CBG-icon" className={styles.bgIcon} />
        </Grid>
        <Grid item xs="auto" md="auto" lg="auto">
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs="auto" md="auto" lg="auto">
              <Typography color="white" variant="h1" className={styles.title}>
                404
              </Typography>
            </Grid>
            <Grid item xs="auto" md="auto" lg="auto">
              <Typography variant="h1" align="center" className={styles.titleBold}>
                Page Not
              </Typography>
            </Grid>
            <Grid item xs="auto" md="auto" lg="auto">
              <Typography variant="h1" align="center" className={styles.titleExtraBold}>
                Found
              </Typography>
            </Grid>
          </Grid>
          <Typography align="center" className={styles.subtitle}>
            The page you are looking for doesn't exist or has been moved.
          </Typography>
        </Grid>
        <Grid item xs="auto" md="auto" lg="auto">
          <Grid container direction="row" justifyContent="flex-end">
            <img src={cyberIcon} alt="cyber-icon" className={styles.cyberIcon} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={4} position="absolute" top="52%" zIndex={1} justifyContent="space-between" className={styles.iconsContainer}>
        <Grid item xs="auto" md="auto" lg="auto">
          <img src={bgIcon} alt="CBG-icon" className={styles.largeIcon} />
        </Grid>
        <Grid item xs="auto" md="auto" lg="auto">
          <img src={cyberIcon} alt="cyber-icon" className={styles.largeIcon} />
        </Grid>
      </Grid>
      <Box marginBottom="9rem"></Box>
      <Box className={styles.buttonContainer}>
      <MainButton
          onClick={handleGoHome}
          className={styles.goHomeButton}
          theme="blue"
          label="Go Home"
        />
      </Box>
    </Box>
  );
};

export default NotFoundPage;
