import { Middleware } from '@reduxjs/toolkit';
import { clearAll } from '../actions';
import { clearUser } from '../userSlice';
import { clearAllData } from '../projectSlice';

const clearAllMiddleware: Middleware = ({ dispatch }) => (next) => (action) => {
    if (clearAll.match(action)) {
        dispatch(clearUser());
        dispatch(clearAllData());
    }
    return next(action);
};

export default clearAllMiddleware;
