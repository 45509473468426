import { useMutation } from '@tanstack/react-query'
import { Logout } from '../../api/firebase';
import { PostParam } from '@/types'

export function useLogout(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: Logout,
        onSuccess,
        onError
    })
}