// src/store/userSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';

interface UserState {
  id: string | null;
  userName: string | null;
  userAuth: boolean;
  token: string | null;
  refreshToken: string | null;
}

const initialState: UserState = {
  id: secureLocalStorage.getItem('userId') as string | null,
  userName: secureLocalStorage.getItem('userName') as string | null,
  userAuth: JSON.parse(secureLocalStorage.getItem('userAuth') || 'false'),
  token: secureLocalStorage.getItem('token') as string | null,
  refreshToken: secureLocalStorage.getItem('refreshToken') as string | null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.id = action.payload.id;
      state.userName = action.payload.userName;
      state.userAuth = action.payload.userAuth;

      secureLocalStorage.setItem('userId', action.payload.id || '');
      secureLocalStorage.setItem('userName', action.payload.userName || '');
      secureLocalStorage.setItem('userAuth', JSON.stringify(action.payload.userAuth));
    },
    setToken: (state, action: PayloadAction<{token: string, refreshToken: string}>) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken

      secureLocalStorage.setItem('token', action.payload.token);
      secureLocalStorage.setItem('refreshToken', action.payload.refreshToken)
    },
    clearUser: (state) => {
      state.id = null;
      state.userName = null;
      state.userAuth = false;
      state.token = null;
      state.refreshToken = null;

      secureLocalStorage.removeItem('userId');
      secureLocalStorage.removeItem('userName');
      secureLocalStorage.setItem('userAuth', 'false');
      secureLocalStorage.removeItem('token');
      secureLocalStorage.removeItem('refreshToken');
    },
  },
});

export const { setUser, setToken, clearUser } = userSlice.actions;
export default userSlice.reducer;
