import React, { useRef, useState } from "react";
import UploadIconSVG from "../../icons/UploadIconSVG.svg";
import SelectButton from "./SelectButton";
import styles from "./css/UploadButton.module.css";

interface Data {
  id: string;
  name: string;
}

interface UploadButtonProps {
  errorState?: boolean;
  errorMessage?: string;
  data?: Data[];
  updateFunction: (id: string, name: string) => void; // Update function now takes an ID
  disabledState?: boolean;
  selectedValue: string;
  onUpload: (file: File) => void;
  selection: boolean;
}

const UploadButton: React.FC<UploadButtonProps> = ({
  errorState,
  errorMessage,
  data,
  updateFunction,
  disabledState,
  selectedValue,
  onUpload,
  selection,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      onUpload(event.target.files[0]);
      // Reset the file input to allow re-uploading the same file
      event.target.value = "";
    }
  };

  const handleSelectChange = (uniqueIdentifier: string) => {
    const selectedData = data?.find(item => `${item.name}_${item.id}` === uniqueIdentifier);
    if(uniqueIdentifier === ""){
      updateFunction("","");
    }
    else if (selectedData) {
      updateFunction(selectedData.id, selectedData.name);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!disabledState) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      onUpload(event.dataTransfer.files[0]);
    }
  };

  return (
    <div
      className={`${styles.container} ${disabledState ? styles.disable : ""} ${isDragging ? styles.dragging : ""}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div
        className={`${styles.uploadSection} ${
          disabledState ? styles.disable : ""
        }`}
      >
        <label
          className={`${styles.uploadButton} ${
            disabledState ? styles.disable : ""
          }`}
        >
          <img
            src={UploadIconSVG}
            alt="upload-icon"
            className={styles.uploadIcon}
          />
          <input
            ref={fileInputRef}
            type="file"
            className={`${styles.fileInput}`}
            onChange={handleFileChange}
            disabled={disabledState}
          />
        </label>
      </div>
      {selection ? (
        data !== undefined && (
          <SelectButton
            data={data.map(item => `${item.name}_${item.id}`)} // Pass unique identifiers to SelectButton
            displayData={data.map(item => item.name)} // Display only the names
            updateFunction={handleSelectChange} // Use the new handleSelectChange function
            disabledState={disabledState}
            selectedValue={selectedValue}
            isUpload={true}
          />
        )
      ) : (
        <input
          disabled={true}
          value={selectedValue}
          className={`${styles.textField} ${
            disabledState ? styles.disable : ""
          }`}
          type="text"
          readOnly
        />
      )}
      <div className={`${styles.error} ${errorState ? styles.showError : ""}`}>
        {errorMessage}
      </div>
    </div>
  );
};

export default UploadButton;
