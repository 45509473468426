// src/components/AlertPopup.tsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { setShowMessage } from '../../store/messageSlice';
import styles from './css/AlertPopup.module.css';

const AlertPopup: React.FC = () => {
  const dispatch = useDispatch();
  const showMessage = useSelector((state: RootState) => state.message.showMessage);
  const message = useSelector((state: RootState) => state.message.message);

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        dispatch(setShowMessage(false));
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showMessage, dispatch]);

  return (
    <div className={`${styles.alertPopup} ${showMessage ? styles.show : ''}`}>
      {message}
    </div>
  );
};

export default AlertPopup;
