import React from "react";
import { MaterialReactTable, MRT_ColumnDef } from "material-react-table";
import { Box } from "@mui/material";
import DownloadButton from "../buttons/DownloadButton";
import { EvaluationData, DetectionData } from "@/types";
import styles from "./css/AllAnalysesTable.module.css";
import StopButton from "../buttons/StopButton";

interface AllAnalysesProps {
  evaluations?: EvaluationData[];
  detections?: DetectionData[];
  type: "ME" | "DE" | "BE";
  data: "detection" | "evaluation";
  onStop: (job_id: string, type: string) => void;
}

const removeSeconds = (dateString: string) => {
  if(dateString){
    const hasSeconds = dateString.split(':').length === 3;
    return hasSeconds ? dateString.slice(0, -3) : dateString; 
  }
  return dateString;
};

const AllAnalysesTable: React.FC<AllAnalysesProps> = ({
  evaluations,
  detections,
  type,
  data,
  onStop,
}) => {
  const columns: MRT_ColumnDef<any>[] = [
    {
      accessorKey: "stop",
      header: "",
      size: 10,
      Cell: ({ row }) =>
        row.original.status === "Running" && (
          <StopButton runId={row.original.id} type={data} onStop={onStop} />
        ),
      muiTableBodyCellProps: {
        className: styles.data,
      },
    },
    {
      accessorKey: "start_time",
      header: "Creation Time",
      size: 50,
      Cell: ({ row }) => {
        return removeSeconds(row.original.start_time)
      },
      muiTableBodyCellProps: {
        className: styles.data,
      },
    },
    ...(type === "ME"
      ? [
          {
            accessorKey: "model_name",
            header: "Model",
            size: 90,
            muiTableBodyCellProps: {
              className: styles.data,
            },
          },
        ]
      : []),
    {
      accessorKey: "dataset_name",
      header: "Dataset",
      size: 90,
      muiTableBodyCellProps: {
        className: styles.data,
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 50,
      Cell: ({ row }) => {
        const status = row.original.status;
        let color = "";
        if (status === "Failed") color = "#FF6F61"; // soft red
        else if (status === "Completed") color = "#6FCF97"; // soft green
        else if (status === "Running") color = "#F2C94C"; // soft yellow
        return <span style={{ color }}>{status}</span>;
      },
      muiTableBodyCellProps: {
        className: styles.data,
      },
    },
    {
      accessorKey: "elapsed_time",
      header: "Elapsed Time",
      size: 80,
      muiTableBodyCellProps: {
        className: styles.data,
      },
    },
    {
      accessorKey: "report",
      header: "Report",
      size: 80,
      Cell: ({ row }) => {
        const hasJson =
          row.original.report && Object.keys(row.original.report).length > 0;
        const hasPdf = row.original.pdf;
        const hasError = row.original.stack_trace || row.original.error
        return (
          <div className={styles.downloadButtons}>
            {hasJson && (
              <DownloadButton
                type="json"
                content={row.original.report}
                filename={`${row.original.job_id}.json`}
              />
            )}
            {hasPdf && (
              <DownloadButton
                type="pdf"
                content={row.original.pdf}
                filename={`${row.original.job_id}.pdf`}
              />
            )}
             {hasError && (
              <DownloadButton
                type="error"
                content={row.original.stack_trace}
                error={row.original.error}
                filename={`${row.original.job_id}.txt`}
              />
            )}
          </div>
        );
      },
      muiTableBodyCellProps: {
        className: styles.data,
      },
    },
  ];

  const analyses = data === "evaluation" ? evaluations : detections;

  const minRows = type === "BE" ? 5 : 8;
  const paddedData =
    analyses && analyses.length >= minRows
      ? analyses
      : [
          ...(analyses || []),
          ...Array(minRows - (analyses ? analyses.length : 0)).fill({}),
        ];

  return (
    <Box className={styles.container}>
      <MaterialReactTable
        columns={columns}
        data={paddedData || []}
        enableColumnResizing={false}
        enableBottomToolbar={false}
        enableStickyHeader
        enableMultiRowSelection={true}
        enablePagination={false}
        enableColumnActions={false}
        enableSorting={false}
        enableGlobalFilter={false}
        enableFilters={false}
        enableTopToolbar={false}
        muiTableProps={{
          className: styles.table,
        }}
        muiTableFooterProps={{
          className: styles.tableFooter,
        }}
        muiTableContainerProps={{
          className: `${styles.tableContainer} ${
            type === "BE" ? styles.be : ""
          }`,
        }}
        muiTableHeadCellProps={{
          className: styles.tableHeadCell,
        }}
        muiTableHeadRowProps={{
          className: styles.tableHeadRow,
        }}
        muiTablePaperProps={{
          className: styles.tablePaper,
        }}
        muiTableBodyRowProps={{
          className: styles.tableBodyRow,
        }}
        state={{
          isLoading: false,
          showAlertBanner: false,
          showProgressBars: false,
        }}
      />
    </Box>
  );
};

export default AllAnalysesTable;
