import { useDispatch } from 'react-redux';
import { useGetAllProjects } from '../../hooks/project/useGetAllProjects';
import { setAllProjects } from '../../store/projectSlice';


export const useFetchAllProjects = () => {
  const dispatch = useDispatch();

  const getAllProjectMutation = useGetAllProjects({
    onSuccess(data) {
      dispatch(setAllProjects(data));
    },
    onError(error) {
      console.log(`Error fetching projects ${error}`);
    },
  });

  return getAllProjectMutation;
};