import { useMutation } from '@tanstack/react-query'
import { runEvaluation } from '../../api/evalDetectApi'
import { PostParam } from '@/types'

export function useRunEvaluation(props?: PostParam) {
    const { onSuccess, onError } = props || {};

    return useMutation({
        mutationFn: runEvaluation,
        onSuccess,
        onError
    })
}