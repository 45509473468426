import { RiErrorWarningLine } from "react-icons/ri";
import { IoLinkOutline } from "react-icons/io5";
import { VscJson } from "react-icons/vsc";
import { BsFilePdf } from "react-icons/bs";
import React, { useState } from "react";
// import { CircularProgress } from "@mui/material";
import styles from "./css/DownloadButton.module.css";
import { useGenerateURL } from "../../hooks/configMng/useGenerateURL";

interface DownloadButtonProps {
  type: "pdf" | "json" | "url" | "error";
  error?: string;
  content: ArrayBuffer | object | string;
  filename: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  type,
  content,
  filename,
  error = "",
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  // const [urlToDownload, setUrlToDownload] = useState<string>("");

  const generateURL = useGenerateURL({
    onSuccess(data) {
      // setUrlToDownload(data.url);
      setLoading(false);
      handleDownload(data.url);
    },
    onError(error) {
      console.log(`Error fetching URL: ${error}`);
      // setUrlToDownload("");
      setLoading(false);
    },
  });

  const handleDownload = (url: string) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    if (type !== "url") {
      URL.revokeObjectURL(url);
    }
  };

  const handleJsonDownload = (content: string | object) => {
    let url: string;
    let objectUrl: string | null = null;

    if (typeof content === "string") {
      url = content;
    } else {
      const jsonString = JSON.stringify(content, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      objectUrl = URL.createObjectURL(blob);
      url = objectUrl;
    }

    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    if (objectUrl) {
      URL.revokeObjectURL(objectUrl);
    }
  };

  const handleTextDownload = (content: string | object) => {
    let textToDownload: string;

    if (!content) {
      textToDownload = `Error: ${error}`;
    } else {
      textToDownload = `Error: ${error}\nStack trace: ${content}`;
    }

    // Create a Blob from the text content
    const blob = new Blob([textToDownload], { type: "text/plain" });

    // Create an object URL for the Blob
    const objectUrl = URL.createObjectURL(blob);

    // Create an anchor element and trigger the download
    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = `${filename}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke the object URL to free up memory
    URL.revokeObjectURL(objectUrl);
  };

  const handleButtonClick = () => {
    if (type === "url" || type === "pdf") {
      setLoading(true);
      generateURL.mutate({ file_url: getPathWithoutBucket(content as string) });
    } else if (type === "error") {
      handleTextDownload(content);
    } else {
      handleJsonDownload(content);
    }
  };

  function getPathWithoutBucket(url: string): string {
    const bucketName = "e2e-mabadata";
    const bucketIndex = url.indexOf(bucketName);
    if (bucketIndex === -1) {
      return url;
    }
    return url.substring(bucketIndex + bucketName.length + 1);
  }

  return (
    <button
      onClick={handleButtonClick}
      className={`${styles.downloadButton} ${
        type === "url" ? styles.urlButton : ""
      }`}
      disabled={loading}
    >
      {type === "pdf" ? (
        <BsFilePdf />
      ) : type === "json" ? (
        <VscJson />
      ) : type === "error" ? (
        <RiErrorWarningLine />
      ) : (
        <>
          {"Download"}
          <IoLinkOutline className={styles.icon} />
        </>
      )}
    </button>
  );
};

export default DownloadButton;
